import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigPost } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em"
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
}

const { Form } = withTypes<FormValues>();

const GetVerificationLink = () => {
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const [verificationLink, setVerificationLink] = useState("");
  const handleSubmit = (inputData: any) => {
    let headers = getConfigPost();

    let data = {
      email: inputData.email,
    };

    axios
      .post(ENDPOINTS.VERIFICATION_LINK, data, { headers })
      .then((res: any) => {
        console.log(res.data[0]);
        setVerificationLink(res.data[0]);
        notify(`success`, "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Failed to get verification link",
          "error",
          "",
          undefined,
          50000
        );
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.email")}
                  disabled={loading}
                />
              </div>
            </div>
            <Notification />
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                Get Verification Link
              </Button>
            </CardActions>
            {verificationLink.length ? <div style={{width: '35em', wordWrap: 'break-word'}}>{verificationLink}</div> : ""}
          </div>
        </form>
      )}
    />
  );
};

export default GetVerificationLink;

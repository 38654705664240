import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

interface Props {
    ledgers?: any;
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

const numFormatter: any = (num:any) => {
    if(num > 999 && num < 1000000){
        return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
}
const TopLedger: FC<Props> = ({ ledgers = []}) => {
    const classes = useStyles();
    const translate = useTranslate();
   
    return (
        <Card className={classes.root}>
            <CardHeader title={translate('pos.dashboard.top_ledger')} />
            <List dense={true}>
                {ledgers.map((record : any) => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`#'`}
                    >

                        <ListItemAvatar>
                            <Avatar src={`${record.beneficiary_logo}?size=32x32`} />
                        </ListItemAvatar>
                            
                        <ListItemText
                            secondary={record.beneficiary_name}
                            style={{ paddingRight: 0 }}
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {numFormatter(record.total)}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default TopLedger;

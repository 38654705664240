import * as React from "react";
import { useEffect, useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import {getConfigGet, getConfigPost} from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  search: {
    padding: '10px',
    margin: '16px'
  },
  tableCell: {
    padding:'0px 10px 5px 10px',
    border:'1px solid gray' 
  },
  button: {
    margin: '1em'
  },
  center: {
    textAlign: 'center'
  },
  moveRight: {
    paddingLeft: "5%",
    margin: "1.5em 0em 0em 1.5em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  Email?: string;
}


const { Form } = withTypes<FormValues>();

const ApplyPromoReferralCode = () => {

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [consumedPromoCode, setConsumedPromoCode] = useState([]);
  const [referral, setReferral] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const [activePromoCode, setActivePromoCode] = useState([]);
  const [allAffiliateCompanies, setAllAffiliateCompanies] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [promoCode, setPromoCode] = useState("")
  const [referralCode, setReferralCode] = useState("")
  const [affiliateName, setAffiliateName] = useState("")
  const [affiliateAmount, setAffiliateAmount] = useState("")

  const setPromoCodeValue = (e: any) => { setPromoCode(e.target.value) }
  const setReferralCodeValue = (e: any) => { setReferralCode(e.target.value)  }
  const setAffiliateValue = (e: any) => { setAffiliateName(e.target.value)  }
  const setAffiliateAmountValue = (e: any) => { setAffiliateAmount(e.target.value)  }

  const clearPromoCodeValue = () => { setPromoCode("") }
  const clearReferralCodeValue = () => { setReferralCode("")  }
  const clearAffiliateCodeValue = () => { setAffiliateName("")  }
  const clearAffiliateAmountValue = () => { setAffiliateAmount("")  }
  
  const handleSubmit = (inputData: any) => {

    setConsumedPromoCode([])
    setUserEmail(inputData.Email)
    clearPromoCodeValue()
    clearReferralCodeValue()
    clearAffiliateCodeValue()
    clearAffiliateAmountValue()
    notify("pos.fetching_the_result");

    let config = getConfigGet({email: inputData.Email})  
  
    axios.get(ENDPOINTS.ADD_PROMOCODE, config)
          .then((res: any) => {
              setActivePromoCode([])
              
              let temp = res?.data[1].length ? res?.data[1] : [0]
              setConsumedPromoCode(temp)
              let activePromoCode = res?.data[0].map((code: any) => { 
                return ({ id: code, name: code })
              })
              setActivePromoCode(activePromoCode)
              setLoading(false);
              // notify(res?.data?.data || 'Success', "info", "", undefined, 20000);
          })
          .catch((error) => {
              setActivePromoCode([])
              notify(error?.response?.data?.message || 'Failed get user account detail', "error", "", undefined, 50000)
          });

    axios.get(ENDPOINTS.ADD_REFERRAL, config)
    .then((res: any) => {
        setReferral(res.data.length ? res.data[0] : [0])
        // notify(res?.data?.data || 'Success', "info", "", undefined, 20000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed get user account detail', "error", "", undefined, 50000)
      });

      axios.get(ENDPOINTS.ADD_AFFILIATES_REWARDS, config)
      .then((res: any) => {
        let temp = res?.data[1].length ? res?.data[1] : [0]
        setAffiliate(temp)

        let affiliateCompanies = res?.data[0].map((company_name: any) => { 
          return ({ id: company_name, name: company_name })
        })
        setAllAffiliateCompanies(affiliateCompanies)
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed get user account detail', "error", "", undefined, 50000)
        });
  
  };
  const validate = (values: FormValues) => {
      const errors: FormValues = {};
      if (!values.Email) {
        errors.Email = translate("ra.validation.required");
      }
      return errors;
    };


  return (
    <>
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="Email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                    Get Used Code
                  </Button>
              </CardActions>
              <Notification />
            </div>
          </form>
          <CodeData  
              userEmail = {userEmail}
              consumedPromoCode = {consumedPromoCode}
              activePromoCode = {activePromoCode}
              allAffiliateCompanies = {allAffiliateCompanies}
              referral = {referral}
              affiliate = {affiliate}
              onPromoCodeSelect = {setPromoCodeValue}
              onReferral = {setReferralCodeValue}
              onAffiliate = {setAffiliateValue}
              onAffiliateAmount = {setAffiliateAmountValue}
              promoCode = {promoCode}
              referralCode = {referralCode}
              affiliateName = {affiliateName}
              affiliateAmount = {affiliateAmount}
              clearPromoCodeValue = {clearPromoCodeValue}
              clearReferralCodeValue = {clearReferralCodeValue}
              clearAffiliateCodeValue = {clearAffiliateCodeValue}
              clearAffiliateAmountValue = {clearAffiliateAmountValue}
          />
          <p>{affiliateName}</p>
          <BulkInsertPromocode></BulkInsertPromocode>
          </div>
        
      )}
    />
    </>
  );
};


const CodeData = ({userEmail,
                       consumedPromoCode,
                       activePromoCode,
                       allAffiliateCompanies,
                       referral,
                       affiliate,
                       onPromoCodeSelect,
                       onReferral,
                       onAffiliate,
                       onAffiliateAmount,
                       promoCode,
                       referralCode,
                       affiliateName,
                       affiliateAmount,
                       clearPromoCodeValue,
                       clearReferralCodeValue,
                       clearAffiliateCodeValue,
                       clearAffiliateAmountValue}: any) => {
    const classes = useStyles();
    const notify = useNotify();

    const onPromoCodeSelectSub = (e: any) => {
      onPromoCodeSelect(e)
    }
    const onReferralSub = (e: any) => {
      onReferral(e)
    }

    const onAffiliateSub = (e: any) => {
      onAffiliate(e)
    }
    const onAffiliateAmountSub = (e: any) => {
      onAffiliateAmount(e)
    }

    const  addPromoCodeOnClick = (e: any) => {
        let headers = getConfigPost()
        
        let data = {
          email: [userEmail],
          promocode: promoCode
        }

        axios.post(ENDPOINTS.ADD_PROMOCODE, data, {headers})
          .then((res: any) => {
            clearPromoCodeValue()
            notify(`${promoCode} added successfully`, "info", "", undefined, 50000);
          })
          .catch((error) => {
            notify(error?.response?.data?.message || 'Failed to add promocode', "error", "", undefined, 50000)
          });
    }  
 
    const addReferralOnClick = (e:any) => {
        let headers = getConfigPost()
          
        let data = {
          email: userEmail,
          referralCode: referralCode
        }

        axios.post(ENDPOINTS.ADD_REFERRAL, data, {headers})
          .then((res: any) => {
            clearReferralCodeValue()
            notify(`${referralCode} added successfully`, "info", "", undefined, 50000);
          })
          .catch((error) => {
            notify(error?.response?.data?.message || 'Failed to add reward', "error", "", undefined, 50000)
          });
    }


    const  addAffiliateOnClick = (e: any) => {
      let headers = getConfigPost()
      let data = {
        email: userEmail,
        company_name: affiliateName,
        amount: affiliateAmount
      }

      axios.post(ENDPOINTS.ADD_AFFILIATES_REWARDS, data, {headers})
        .then((res: any) => {
          clearAffiliateCodeValue()
          clearAffiliateAmountValue()
          notify(`${affiliateName} added successfully`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed to add promocode', "error", "", undefined, 50000)
        });
  }  

    return (
      <>  
          {consumedPromoCode.length>1 ?
            <div>
                <ShowData record={consumedPromoCode} tableCenterAlign={false}/>
            </div> : null}
         
          {referral.length>1 ? 
          <div>
              <ShowData record={referral} tableCenterAlign={false}/>
          </div> : null}

          {affiliate.length>1 ? 
          <div>
              <ShowData record={affiliate} tableCenterAlign={false}/>
          </div> : null}



            <div className={classes.center}>
              {activePromoCode.length ?  
                  <div>
                    <SelectInput onChange={onPromoCodeSelectSub} 
                              autoFocus
                              source="Promo Code" 
                              choices= {activePromoCode}
                              value={promoCode} />
                    <Button
                      variant="contained"
                      type="submit"
                      color='primary'
                      className={classes.button}
                      onClick={addPromoCodeOnClick}
                      disabled = {!promoCode}
                      >Add Promo Code
                    </Button>
                  </div>
                : null}

              {allAffiliateCompanies.length ?  
              
                  <div>
                    {affiliateName}
                    <SelectInput onChange={onAffiliateSub} 
                              source="Company name" 
                              choices= {allAffiliateCompanies}
                              value={affiliateName} />
                      <TextField
                      style={{marginLeft: "2em"}}
                      label = "Amount"
                      onChange={onAffiliateAmountSub}
                      value={affiliateAmount}
                    />  
                    <Button
                      variant="contained"
                      type="submit"
                      color='primary'
                      className={classes.button}
                      onClick={addAffiliateOnClick}
                      disabled = {!affiliateName}
                      >Add Affiliate Rewards
                    </Button>
                  </div>
                : null}
              {referral.length>=1 ?  
                  <div>
                      <TextField 
                          label = "Referral Code"
                          onChange={onReferralSub}
                          value={referralCode}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        color='primary'
                        onClick={addReferralOnClick}
                        disabled={!referralCode}
                        >Add Referral
                      </Button>
                  </div>
              : null }
            </div>
      </>
  )
}

const BulkInsertPromocode = (() => {
  const classes = useStyles();
    const notify = useNotify();

    const [activePromoCode, setActivePromoCode] = useState([])
    const [bulkpromoCode, setBuldPromoCode] = useState("")
    const [csvOutput, setCsvOutput] = useState([]);
    const [failedUsers, setFailedUsers] = useState([]);
    const fileReader = new FileReader();

    const clearPromoCodeValue = () => { setBuldPromoCode("") }

    const setPromoCodeValue = (e: any) => { setBuldPromoCode(e.target.value) }

    const handleOnChange = (e: any) => {
    if (e.target.files[0]) {
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = function (event: any) {
        const csvOutput = event.target.result;
        let temp = csvOutput.split("\n");
        temp = temp.filter((t: any) => {
          return t !== "";
        });
        setCsvOutput(temp);
      };
    }
  };
    const  addPromoCodeOnClick = (e: any) => {
      let headers = getConfigPost()
      
      let data = {
        email: csvOutput  ,
        promocode: bulkpromoCode
      }

      axios.post(ENDPOINTS.ADD_PROMOCODE, data, {headers})
        .then((res: any) => {
          setFailedUsers(res.data)
          clearPromoCodeValue()
          notify(`${bulkpromoCode} added successfully`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed to add promocode', "error", "", undefined, 50000)
        });
      clearPromoCodeValue()
  }  

  
    useEffect(() => {
    
      const config = getConfigGet({});
      const dataRes = async () =>
        axios
          .get(ENDPOINTS.ADD_PROMOCODE, config)
          .then((res: any) => {
            let activePromoCode = res?.data[0].map((code: any) => { 
              return ({ id: code, name: code })
            })
            setActivePromoCode(activePromoCode)
          })
          .catch((error) => {
            notify(error?.response?.data?.message, "error", "", undefined, 50000);
          });
  
      dataRes();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    
  return (
    <>  
          <div className={classes.center}>
            <div>
             <h2 style={{ paddingTop: "4em" }}>Bulk Update Promocode</h2>
             <SelectInput 
              onChange={setPromoCodeValue} 
                    autoFocus
                    source="Bulk Promo Code" 
                    choices= {activePromoCode}
                    value="bulkpromoCode"
                />
             <input
                className={classes.moveRight}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    onClick={handleOnChange}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    color='primary'
                    className={classes.button}
                    onClick={addPromoCodeOnClick}
                    // disabled = {!promoCode}
                    >Bulk Update Promocode
                  </Button>
                  {failedUsers.length ? (
                    <>
                      <p>Failure Count: {failedUsers.length}</p>
                      <p>{(failedUsers).join(", ")}</p>
                    </>
                  ) : ""}
            </div>
          </div>
    </>
)
})

export default ApplyPromoReferralCode;

import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import {getConfigGet, getConfigPost} from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  button: {
    margin: '1em'
  },
  center: {
    textAlign: 'center'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  Email?: string;
}


const { Form } = withTypes<FormValues>();

const UpdatePendingTask = () => {

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [userPendingTask, setUserPendingTask] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [task, setTask] = useState([]);
  const [taskStages, setTaskStages] = useState([]);
  
    let [selectedTask, setSelectedtask] = useState("")
    let [selectedTaskStage, setSelectedTaskStage] = useState("")
    let [selectedTaskStageName, setSelectedTaskStageName] = useState("") 
  
  const handleSubmit = (inputData: any) => {

    setUserPendingTask([])
    setUserEmail(inputData.Email)
    notify("pos.fetching_the_result");

    let config = getConfigGet({email: inputData.Email})  

    axios.get(ENDPOINTS.UPDATE_PENDING_TASK, config)
          .then((res: any) => {
              setLoading(false);
              clearSelectedOptions()
              setUserPendingTask(res?.data[0])
              let task = res?.data[1].map((code: any) => { 
                return ({ id: code, name: code })
              })
              setTask(task)
              let taskStage = res?.data[2].map((code: any, index: any) => { 
                return ({ id: index, name: Object.values(code)[0], key: Object.keys(code)[0] })
              })
              setTaskStages(taskStage)
              notify(res?.data?.data || 'Success', "info", "", undefined, 20000);
          })
          .catch((error) => {
              notify(error?.response?.data?.message || 'Failed get pending task', "error", "", undefined, 50000)
          });
  };

  const onSelectTask = (e: any) => { setSelectedtask(e.target.value)  }

  const clearSelectedOptions = () => { setSelectedtask(""); setSelectedTaskStage(""); setSelectedTaskStageName("") }

  const onSelectTaskStage = (e: any) => { 
    setSelectedTaskStage(e.target.value)
    setSelectedTaskStageName((taskStages[e.target.value] as any).name)  
  }

  
  const validate = (values: FormValues) => {
      const errors: FormValues = {};
      if (!values.Email) {
        errors.Email = translate("ra.validation.required");
      }
      return errors;
    };


  return (
    <>
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="Email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                    Get Pending Task
                  </Button>
              </CardActions>
              <Notification />
            </div>
          </form>
          <PendingTask  
              userEmail = {userEmail}
              userPendingTask = {userPendingTask}
              task = {task}
              taskStages = {taskStages}
              selectedTask = {selectedTask}
              selectedTaskStage = {selectedTaskStage}
              onSelectTask = {onSelectTask}
              onSelectTaskStage = {onSelectTaskStage}
              clearSelectedOptions = {clearSelectedOptions}
              selectedTaskStageName = {selectedTaskStageName}
          />
        
          </div>
        
      )}
    />
    </>
  );
};


const PendingTask = ({userEmail,
                       userPendingTask,
                       task,
                       taskStages,
                       selectedTask,
                       selectedTaskStage,
                       onSelectTask,
                       onSelectTaskStage,
                       selectedTaskStageName,
                       clearSelectedOptions }: any) => {
    const classes = useStyles();
    const notify = useNotify();

    let [filterTaskStage, setFilterTaskStage] = useState([])
    

    const onSelectTaskSub = (e: any) => {
      onSelectTask(e)
      let temp = taskStages.filter((stages: any) => {
        return Number(stages.key) === Number(e.target.value)
      })
      setFilterTaskStage(temp)
    }

    const onSelectTaskStageSub = (e: any) => {
      onSelectTaskStage(e)
    }
    const  updatePendingTaskOnClick = (e: any) => {
        let headers = getConfigPost()
        
        let data = {
          email: userEmail,
          task_id: selectedTask,
          task_stage_name: selectedTaskStageName
        }

       
        
        axios.patch(ENDPOINTS.UPDATE_PENDING_TASK, data, {headers})
        .then((res: any) => {
          clearSelectedOptions()
          notify(`Success: task_id: ${selectedTask} last_stage_completed: ${selectedTaskStageName}`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || `Failed `, "error", "", undefined, 50000)
        });
    }  
 
    return (
      <>  
        {userPendingTask.length>1 ?
            <div>
                <ShowData record={userPendingTask} tableCenterAlign={false}/>
            </div> : null}
        
       
            <div className={classes.center}>
              {task.length ?  
                  <div>
                    <SelectInput onChange={onSelectTaskSub} 
                              autoFocus
                              source="Task Id" 
                              value={selectedTask}
                              choices= {task} />
                    <SelectInput onChange={onSelectTaskStageSub} 
                              autoFocus
                              source="Last Stage Completed" 
                              choices= {filterTaskStage}
                              value={selectedTaskStage}
                              disabled={!selectedTask}
                              style={{paddingLeft:'10px'}} />
                    <Button
                      variant="contained"
                      type="submit"
                      color='primary'
                      className={classes.button}
                      onClick={updatePendingTaskOnClick}
                      disabled = {!selectedTaskStageName}
                      >Update Pending Task
                    </Button>
                  </div>
                : null}

        
            </div>
      </>
  )
}


export default UpdatePendingTask;

import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";


import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { Notification, useTranslate, useNotify, useLogin } from "react-admin";

import { lightTheme } from "./themes";

import { Link } from "react-router-dom";
import { validatePassword } from "./validatePassword";
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://cdn.cjr.org/wp-content/uploads/2019/07/AdobeStock_100000042-e1563305717660-1300x500.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
  resetPassword?: string;
  confirmResetPassword?: string
}

const { Form } = withTypes<FormValues>();

const Login = () => {
//  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [timeToResetPassword, setTimeToResetPassword] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  //const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    const {username,password, resetPassword, confirmResetPassword} = auth;
  
    if(resetPassword && confirmResetPassword && confirmResetPassword !== resetPassword){
      notify("Reset password and confirm reset password Doesn't match",'warning');
      return
    }
    if(password === resetPassword){
      notify("Old and new password can not be same",'warning');
      return
    }
    setLoading(true);
    login({username, password, resetPassword}).then((res) => {
        setLoading(false);
        window.location.href="/";
    }).catch((error) => {
        if(error.message === 'time to reset') setTimeToResetPassword(true)
        setLoading(false);
        notify(
            typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
            'warning'
        );
    })
};


  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if(timeToResetPassword && values.resetPassword){
      const isNotStrongPassword = validatePassword(values.resetPassword)
      if(isNotStrongPassword.length){
        errors.resetPassword = isNotStrongPassword
      }
      if (!values.confirmResetPassword) {
        errors.confirmResetPassword = translate("ra.validation.required");
      }
    }
    return errors;
  };

  return (
    <div className={classes.main}>
      <div>
        <Card className={classes.card}>
          <div>
            <div className={classes.hint}>New User?</div>
            <br />
          </div>

          <Link to={{ pathname: "/register" }}>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                SIGN UP
              </Button>
            </CardActions>{" "}
          </Link>
        </Card>
      </div>

      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Avatar className={classes.icon}>
                    <LockIcon />
                  </Avatar>
                </div>
                <div className={classes.hint}>Login</div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.username")}
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                  {timeToResetPassword ? 
                      <div className={classes.input}>
                        <Field
                          name="resetPassword"
                          // @ts-ignore
                          component={renderInput}
                          label={translate("reset-password")}
                          type="password"
                          disabled={loading}
                        />
                         <Field
                          name="confirmResetPassword"
                          // @ts-ignore
                          component={renderInput}
                          label={translate("confirm-reset-password")}
                          type="password"
                          disabled={loading}
                        />
                    </div>: ''}
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
              </Card>
              <Notification />
            </div>
          </form>
        )}
      />
    </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;

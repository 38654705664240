import {  restClient } from 'ra-data-feathers';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import './App.css';
import { Dashboard } from './dashboard';
import category from './debitCalculator';
import userSessionInfo from './userSessionInfo'
import userPayments from './userPayments'
import authProvider from "./authProvider";
// External Modules
import feathersClient from './feathersClient';
// Imported Modues 
import grid from './grid';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import customRoutes from './routes';
// Configuration modules
import themeReducer from './themeReducer';


const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    const [dataProvider, setDataProvider] = useState<Boolean>();

    useEffect(() => {
        let restoreFetch;

        setDataProvider(true);

        return restoreFetch;
    }, []);
    // Data Provider Loading Screen
    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }
    // Feather Client Configration Options
    const restClientOptions = {
        id: 'id', // In this example, the database uses '_id' rather than 'id'
        usePatch: true, // Use PATCH instead of PUT for updates
        resource:{
            customQueryOperators: ['_gt', '_gte', '_lt', '_lte', '_ne', '$sort', '$or', '$nin', '$in'],
        }
      
      };
      
    //   const authClientOptions = {
    //     usernameField: 'email', // Our example database might use 'username' rather than 'email'
    //     permissionsField: 'role', // Use the 'userroles' field on the JWT as the users role
    //     redirectTo: '/login', // Our example login form might be at '/signin', redirect here if AUTH_CHECK fails
    //   }
    return (
        <Admin
            title="Sprive Admin"
            authProvider={authProvider}
            dataProvider={restClient(feathersClient, restClientOptions)}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
           
                {/* <Resource name="admin/grid/users" options={{ label: 'users' }} {...users} /> */}
                {/* <Resource name="issues" options={{ label: 'Issues' }} {...issues} /> */}
                <Resource name="admin/grid" options={{ label: 'Grid' }} {...grid} />
                <Resource name="admin/monitor-debit-algo-performance" options={{ label: 'Debit Calculator' }}  {...category}  />
                <Resource name="admin/inactive-users" options={{ label: 'User Session Info' }}  {...userSessionInfo}  />
                {/* <Resource name="admin/filter-users" options={{ label: 'Filter Users' }}  {...filterUsers}  /> */}
                <Resource name="display-payments" options={{ label: 'User Payments' }}  {...userPayments}  />
        </Admin>
    );
};

export default App;

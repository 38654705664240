import * as React from 'react';
import { FC } from 'react';
 
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

interface Props {
    value?: any;
    type?: any;
    icon?: any;
}

const numFormatter: any = (num:any) => {

    const COUNT_ABBRS = [ '', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y' ];
    const withAbbr = true, decimals = 2;

    const i     = 0 === num ? num : Math.floor(Math.log(num) / Math.log(1000));
    let result : any = parseFloat((num / Math.pow(1000, i)).toFixed(decimals));
    if(withAbbr) {
        result += `${COUNT_ABBRS[i]}`; 
    }
    return result;
    // if(num > 999 && num < 1000000){
    //     return (num/1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million 
    // }else if(num > 1000000){
    //     return (num/1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million 
    // }else if(num < 900){
    //     return num; // if value < 1000, nothing to do
    // }
}

const TotalPayment: FC<Props> = ({ value,type,icon }) => {

   

    const translate = useTranslate();
    let iconType :any = '';
     if(icon==="target"){
        iconType=TrackChangesIcon;
    }
   
    return (
        <CardWithIcon
         to={'#'}
            icon={iconType}
            title={translate(type)}
            subtitle={numFormatter(parseFloat(value))}
        />
    );
};

export default TotalPayment;

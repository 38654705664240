import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";

import CustomConfirm from "ra-custom-confirm";
import Check from "@material-ui/icons/Check";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 600,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
    minWidth: 400,
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
}

const { Form } = withTypes<FormValues>();

const AccountClosure = () => {
  const [loading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [accountClosureInProgress, setAccountClosureInProgress] = useState(false);
  const [accountClosureResult, setAccountClosureResult] = useState([])
  const [settlementDate, setSettlementDate] = useState(null)
  // const [userDetails, setUserDetails] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const accountClosure = (inputData: any) => {
    const { email } = inputData;
    let accountClosureInProgress;
    setInputEmail(email);
    let config = getConfigGet({ email: email });
    axios
      .get(ENDPOINTS.ACCOUNT_CLOSURE, config)
      .then((res: any) => {
        accountClosureInProgress = res.data[0];
        if (accountClosureInProgress) {
          const isClosed = accountClosureInProgress[1][1];
          setAccountClosureInProgress(!isClosed);
          if (isClosed === 0) {
            const settlementDate = accountClosureInProgress[1][2]
            setSettlementDate(settlementDate)
            setOpen(true);
          } else {
            handleConfirm(email);
          }
        } else {
          handleConfirm(email);
        }
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Error while account closure calling get",
          "error",
          "",
          undefined,
          50000
        );
      });
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (email: string) => {
    let headers = getConfigPost();
    let data = {
      email: email,
      accountClosureInProgress: accountClosureInProgress,
    };

    axios
      .post(ENDPOINTS.ACCOUNT_CLOSURE, data, { headers })
      .then((res: any) => {
        setAccountClosureResult(res.data)
        notify(res?.data?.data || "Success", "info", "", undefined, 50000);
        setOpen(false);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Error while account closure calling create",
          "error",
          "",
          undefined,
          50000
        );
        setOpen(false);
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={accountClosure}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Account Closure
                </Button>
              </CardActions>
              <CustomConfirm
                isOpen={open}
                title="Account Closure is in progress."
                date = {settlementDate}
                content={CustomConfirmContent}
                confirm="OK"
                confirmColor="primary"
                ConfirmIcon={Check}
                cancel="Cancel"
                CancelIcon={ErrorOutline}
                onConfirm={() => handleConfirm(inputEmail)}
                onClose={handleDialogClose}
              />
               {accountClosureResult.length>1 ? 
          <div>
              <ShowData record={accountClosureResult} tableCenterAlign={false}/>
          </div> : null}
              <Notification />
            </div>
          </form>
        )}
      />
    </>
  );
};

const CustomConfirmContent = (props: any) => {
  const settlementDate: any = props.date;
  return (
    <>
      <h4>Settlement date is {settlementDate}.</h4>
      <p>Clicking on ok will close the account if there is no amount in flight, otherwise will do nothing.</p>
    </>
  );
};

export default AccountClosure;

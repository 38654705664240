import * as React from "react";
import { useEffect, useState } from "react";

import axios from "axios";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet } from "../getConfig";
import Counts from "../dashboard/Counts";
import { ShowData } from "./transferRewardAmount";

const styles = {
  flex: { display: "flex", marginTop: 10, maxWidth: '60em' },
  flexTable: { display: "flex",  borderRadius: "0.5em" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;

const GiftCardDashboard = () => {
  const [totalTable, setTotalTable] = useState<any>([]);
  const [maxValues, setMaxValues] = useState<any>([]);
  const [lastThirtyDaysData, setLastThirtyDaysData] = useState<any>([]);
  const [ageCategorisation, setAgeCategorisation] = useState<any>([]);
  const [cityCategorisation, setCityCategorisation] = useState<any>([]);
  const [sectorCategory, setSectorCategory] = useState<any>([]);
  useEffect(() => {
    const config = getConfigGet({});
    const dataRes = async () =>
      axios
        .get(ENDPOINTS.GIFTCARD_DASBOARD, config)
        .then((res: any) => {
          console.log(res.data);
          setTotalTable(res.data[0].totalTable);
          setMaxValues(res.data[0].maxValues);
          setLastThirtyDaysData(res.data[0].lastThirtyDaysTable)       
          setAgeCategorisation(res.data[0].ageCategorisation);
          setCityCategorisation(res.data[0].cityCategorisation);
          setSectorCategory(res.data[0].sectorCategorisation);
        })
        .catch((error) => {});

    dataRes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div style={styles.flex}>
        {totalTable ? (
          <>
            {Object.keys(totalTable).map((e: any) => (
              <>
                <Counts icon={totalTable[e][1]} type={e} value={totalTable[e][0]} />
                <Spacer />
              </>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div style={styles.flex}>
        {maxValues ? (
          <>
            {Object.keys(maxValues).map((e: any) => (
              <>
                <Counts icon={maxValues[e][1]} type={e} value={maxValues[e][0]} />
                <Spacer />
              </>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <div style={styles.flex}>
        {lastThirtyDaysData ? (
          <>
            {Object.keys(lastThirtyDaysData).map((e: any) => (
              <>
                <Counts icon={lastThirtyDaysData[e][1]} type={e} value={lastThirtyDaysData[e][0]} />
                <Spacer />
              </>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <h3>Age-specific   Categorisation</h3>
      <div style={styles.flexTable}>
        <ShowData
          dataGridStyle="datagrid-body MuiTableCell-sizeSmall"
          record={ageCategorisation}
          tableCenterAlign={false}
        />
      </div>
      <h3>Location-specific Categorisation</h3>
      <div style={styles.flexTable}>
        <ShowData
          dataGridStyle="datagrid-body MuiTableCell-sizeSmall"
          record={cityCategorisation}
          tableCenterAlign={false}
        />
      </div>
      <h3>Sector-specific Categorisation</h3>
      <div style={styles.flexTable}>
        <ShowData 
          dataGridStyle="datagrid-body MuiTableCell-sizeSmall"
          record={sectorCategory}
          tableCenterAlign={false}
        />
      </div>
    </>
  );
};

export default GiftCardDashboard;

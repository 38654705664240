import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import {  makeStyles } from "@material-ui/core/styles";

import LockIcon from "@material-ui/icons/Lock";
import { Notification, useTranslate, useNotify } from "react-admin";


import { API } from "../Constant";
import { validatePassword } from "./validatePassword";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://cdn.cjr.org/wp-content/uploads/2019/07/AdobeStock_100000042-e1563305717660-1300x500.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
  passwordReEntered?: string
}

const { Form } = withTypes<FormValues>();

const Register = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
 // const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth:any) => {
    setLoading(true);
    const { username, password, passwordReEntered  } = auth;
    
    if(password===passwordReEntered){
      axios({
        method: "post",
        url: API + "/admin-register",
        headers: { "Content-Type": "application/json" },
        data: {
          email: username,
          password: password,
        },
      })
        .then((res: any) => {
            console.log('RESPONSE',res.data.data.token)
           localStorage.setItem('accessToken', res.data.data);
          window.location.href="/";
        })
        .catch((error) => {
                notify(error.response.data.message,"error")
                setLoading(false);
        });
    }else{
      notify("Password Re-Entered Doesn't match", "error")
      setLoading(false);
    }
   

  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }else{
      const isNotStrongPassword = validatePassword(values.password)
      if(isNotStrongPassword.length){
        errors.password = isNotStrongPassword
      }
    }
    if (!values.passwordReEntered) {
      errors.passwordReEntered = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>Register</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("ra.auth.username")}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("ra.auth.password")}
                    type="password"
               
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="passwordReEntered"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("Confirm Password")}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  SIGN UP
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default Register;

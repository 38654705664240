import * as React from "react";
import { useState } from "react";

import { withTypes } from "react-final-form";
import axios from "axios";

import { Button, CardActions, CircularProgress } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Notification,
  useTranslate,
  useNotify,
  DateInput,
  minValue,
  maxValue,
  downloadCSV,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet } from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 500,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  secondInput: {
    marginLeft: "0.5em"
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  tableOuterDiv: {
    border: "1px solid lightgray",
    borderRadius: "0.5em",
  },
  exportButton: {
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "70px",
  },
}));

interface FormValues {
  startDate?: string;
  endDate?: string;
}

const { Form } = withTypes<FormValues>();

const PromoCodeRecords = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [promoCodeRecord, setPromoCodeRecord] = useState([]);
  const currentDate = moment().format("YYYY-MM-DD");
  const [noFilter, setNoFilter] = useState(true);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const handleSubmit = (props: any) => {
    const { startDate, endDate } = props;
    setStartDate(startDate)
    setEndDate(endDate)
    setPromoCodeRecord([]);
    notify("pos.fetching_the_result");

    let config;
    config = getConfigGet({ startDate: startDate, endDate: endDate });
    if (noFilter){
      config = getConfigGet({ noFilter: true });
      setStartDate("")
      setEndDate("")
    } 

    axios
      .get(ENDPOINTS.PROMO_CODES, config)
      .then((res: any) => {
        setLoading(false);
        setPromoCodeRecord(res?.data);
        notify(res?.data?.data || "Success", "info", "", undefined, 20000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Failed to get",
          "error",
          "",
          undefined,
          50000
        );
      });
  };


  const customExporter = (records: any) => {
    const fileName = startDate
      ? moment(startDate).format('ll') + "-" + moment(endDate).format('ll')+"_promocode"
      : "full_promocode_record_"+ moment().format('ll')
    records =
      records.map((item: any) => { return item.join(",") }).join("\n");
    return downloadCSV(records, fileName);
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!noFilter) {
      if (!values.startDate) {
        errors.startDate = translate("ra.validation.required");
      }
      if (!values.endDate) {
        errors.endDate = translate("ra.validation.required");
      }
      if(values.startDate && values.endDate && values.endDate < values.startDate){
        errors.endDate = translate("validation.invalidInput");
      } 
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
              <div className={classes.exportButton}>
              <Button
                className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-46 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                onClick={() => {
                  customExporter(promoCodeRecord);
                }}
                disabled={
                  !promoCodeRecord.length
                }
              >
                <svg
                  className="MuiSvgIcon-root RaButton-smallIcon-49"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                </svg>{" "}
                Export
              </Button>
            </div>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <DateInput
                      source="startDate"
                      validate={[minValue("2021-01-01"), maxValue(currentDate)]}
                    />
                    <DateInput
                      className={classes.secondInput}
                      source="endDate"
                      validate={[minValue("2021-01-01"), maxValue(currentDate)]}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={() => {
                      setNoFilter(false);
                    }}
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Get Filtered Record
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={() => {
                      setNoFilter(true);
                    }}
                    disabled={loading}
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Get Full Record
                  </Button>
                </CardActions>
                <Notification />
                {promoCodeRecord.length > 1 ? (
                  <div className={classes.tableOuterDiv}>
                    <ShowData
                      dataGridStyle="datagrid-body MuiTableCell-sizeSmall"
                      record={promoCodeRecord}
                      tableCenterAlign={false}
                    />
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

export default PromoCodeRecords;

import { AuthProvider } from 'react-admin';
import { API } from './Constant';

const authProvider: AuthProvider = {
    login:  ({username, password, resetPassword})  => {
        console.log('Login Method ')
         let body = {
             email: username,
             password: password,
             resetPassword: resetPassword
         }
          return fetch( API + "/admin-login",{
             method: "POST",
             body: JSON.stringify(body),
             headers: { "Content-Type": "application/json" },
         })
            .then((response) => response.json())
            .then((res)=>{
                if(res.status) {
                    console.log('Access',res.data.token)
                    localStorage.setItem('accessToken', res.data.token);
                    return Promise.resolve(res);
                } else {
                    console.log('Error from backend in then : ', res);
                    return Promise.reject(res);
                }
         }).catch((err)=>{
             console.log('Error from backend : ',err);
             return Promise.reject(err);
         })
    },
    logout: () => {
        localStorage.removeItem('accessToken');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject(),
    getPermissions: () =>  localStorage.getItem('permissions') ? Promise.resolve() : Promise.reject(),
};

export default authProvider;

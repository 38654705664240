import * as React from 'react';
import { FC } from 'react';
 
import {
    // Datagrid,
    // TextField,
    List,
    // EmailField,
    useListContext,
    Responsive,
    ExportButton,
    // NumberField,
    sanitizeListRestProps,
    TopToolbar
} from 'react-admin';
 
const ListActions = (props:any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        filterValues,
        total,
    } = useListContext();
   

    // window.location.reload()
    return ( 
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            {/* Add your custom actions */}
             
        </TopToolbar>

     );
};

const GridList: FC<any> = (props: any) => {
    let pperPage = 5;
   
  
    let sort = {
        field: 'id', order: 'DESC'
    };
   
    // const postRowClick = (props:any) => {
    //     return "";
    // }

    return (
        <>
        {/* Add your custom actions */}
        <Responsive medium={
           <List
                className="grid-list"
                bulkActionButtons={false}
                // bulkActionButtons={<PostBulkActionButtons />}
                title={'Debit Calculator'}
                actions={<ListActions sort={sort} />}
                {...props}
                sort={sort}
                perPage={pperPage}>
               <>
               {/* <Datagrid rowClick={postRowClick}   hasBulkActions={false}  >
               <NumberField label={"id"} source="id" />
               <NumberField label={"User Id"} source="user_id" />
               <EmailField label={"Email"} source="email" />
                    <TextField
                               label={'Calculation Date'} source="calculation_date"  />
                    <TextField 
                               label={'Instruction Date'} source="instruction_date"  />
                    <TextField 
                               label={'Collection Date'} source="collection_date"  />
                   <TextField label={'Direct Debit Category'} source="algoCategory" />
                   <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Amount"} source="amount" />
                   <TextField label={'Algo Debit Percentage'} source="algo_debit_percent" />
                   <TextField label={'Transaction Status'} source="transaction_status" />
                   <TextField label={'User Cancelled'} source="user_cancelled" />
                </Datagrid> */}
            </>
        </List>
        } />
        </>
    );
};

export default GridList;


import React, { useState, useEffect, useCallback, FC } from "react";
import { useVersion, useDataProvider } from "react-admin";

import Counts from "./Counts";
import Payment from "./Payment";
import TopCities from "./TopCities";
import TopLenders from "./TopLenders";

interface State {
  topLender?: any[];
  topCities?: any[];
  matrix?: any;
}

const styles = {
  flex: { display: "flex", marginTop: 10 },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;

const Dashboard: FC = () => {
  const [state, setState] = useState<State>({});
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchDashboard = useCallback(async () => {
    let { data: topLender } = await dataProvider.getOne<any>("admin/grid", {
      id: "top_lender",
    });

    let { data: topCities } = await dataProvider.getOne<any>("admin/grid", {
      id: "top_city",
    });

    let { data: matrix } = await dataProvider.getOne<any>("admin/grid", {
      id: "matrix",
    });
    // console.log('Matrix',matrix)

    setState((state) => ({
      ...state,
      topCities: topCities.data,
      topLender: topLender.data,
      matrix: matrix.data.length > 0 ? matrix.data[0] : false,
    }));
  }, [dataProvider]);

  useEffect(() => {
    fetchDashboard();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const { topLender, topCities, matrix } = state;

  return (
    <div style={styles.flex}>
      <div style={styles.leftCol}>
      <div style={styles.flex}>
          <Counts
            icon="age"
            type={"pos.dashboard.registered_users"}
            value={
              matrix ? parseFloat(matrix["registered_users"]).toFixed(0) : 0
            }
          />
          <Spacer />
          <Counts
            icon="age"
            type={"pos.dashboard.onboarded_users"}
            value={
              matrix ? parseFloat(matrix["onboarded_users"]).toFixed(0) : 0
            }
          />
          <Spacer />
          <Counts
            icon="age"
            type={"pos.dashboard.ewallet_users"}
            value={matrix ? parseFloat(matrix["ewallet_users"]).toFixed(0) : 0}
          />
          <Spacer />
          <Counts
            icon="age"
            type={"pos.dashboard.manual_users"}
            value={matrix ? parseFloat(matrix["manual_users"]).toFixed(0) : 0}
          />
                    <Spacer />
          <Counts
            icon="age"
            type={"pos.dashboard.last_used_less_than_thirty"}
            value={matrix ? parseFloat(matrix["last_used_less_than_thirty"]).toFixed(0) : 0}
          />
        </div>
      <div style={styles.flex}>
        
      <Counts
            icon="age"
            type={"pos.dashboard.total_active"}
            value={matrix ? parseFloat(matrix["total_active"]).toFixed(0) : 0}
          />
          <Spacer />
      <Counts
            icon="age"
            type={"pos.dashboard.active_bps_payer"}
            value={matrix ? parseFloat(matrix["active_bps_payer"]).toFixed(0) : 0}
          />
          <Spacer />

          <Counts
            icon="age"
            type={"pos.dashboard.creation_pending_bps_payer"}
            value={matrix ? parseFloat(matrix["creation_pending_bps_payer"]).toFixed(0) : 0}
          />


        </div>
          
        
        <div style={styles.flex}>
          <Payment
            icon="dollar"
            type={"pos.dashboard.total_payment"}
            value={matrix ? matrix["total_payment"] : 0}
          />
          <Spacer />

          <Payment
            icon="dollar"
            type={"pos.dashboard.monthly_revenue"}
            value={matrix ? parseFloat(matrix["avg_payment"]).toFixed(2) : 0}
          />
                    <Spacer />
          <Counts
            icon="avg"
            type={"pos.dashboard.no_payment"}
            value={matrix ? parseFloat(matrix["no_payment"]).toFixed(0) : 0}
          />


        </div>

        <div style={styles.flex}>

        <Payment
            icon="dollar"
            type={"pos.dashboard.avg_balance"}
            value={matrix ? matrix["avg_balance"] : 0}
          />
          <Spacer />
          <Counts
            icon="calender"
            type={"pos.dashboard.avg_terms"}
            value={matrix ? parseFloat(matrix["avg_term"]).toFixed(0) : 0}
          />
          <Spacer />
          <Payment
            icon="dollar"
            type={"pos.dashboard.avg_payment"}
            value={matrix ? parseFloat(matrix["montly_payment"]).toFixed(2) : 0}
          />


        </div>

        
        <div style={styles.flex}>
        
<Payment
            icon="dollar"
            type={"pos.dashboard.total_mortgage_balance"}
            value={matrix ? parseFloat(matrix["total_mortgage_balance"]).toFixed(2) : 0}
          />

          <Spacer />
          <Counts
            icon="age"
            type={"pos.dashboard.avg_age"}
            value={matrix ? parseFloat(matrix["age"]).toFixed(0) : 0}
          />
          <Spacer />
          <Payment
            icon="home"
            type={"pos.dashboard.avg_home_val"}
            value={matrix ? parseFloat(matrix["avg_home_val"]).toFixed(0) : 0}
          />
        </div>
        
        

        <div style={styles.flex}>
          <TopCities cities={topCities} />
          <Spacer />
          <TopLenders ledgers={topLender} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

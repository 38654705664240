import * as React from "react";
import { useState } from "react";

import { withTypes } from "react-final-form";
import axios from "axios";

import { Button, CardActions, CircularProgress } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Notification,
  useTranslate,
  useNotify,
  DateInput,
  minValue,
  maxValue,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet } from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  button: {
    margin: "1em",
  },
  center: {
    textAlign: "center",
  },
}));

interface FormValues {
  date?: string;
}

const { Form } = withTypes<FormValues>();

const MasterToEwalletFailure = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [displayAmount, setDisplayAmount] = useState([]);
  const [bankChangedUsers, setBankChangedUsers] = useState([]);
  const currentDate = moment().format("YYYY-MM-DD");

  const handleSubmit = (inputData: any) => {
    setDisplayAmount([]);
    setBankChangedUsers([]);
    notify("pos.fetching_the_result");

    let config = getConfigGet({ date: inputData.date });

    axios
      .get(ENDPOINTS.MASTER_TO_USER_EWALLET_FAILURE, config)
      .then((res: any) => {
        setLoading(false);
        setDisplayAmount(res?.data[0]);
        if (res?.data[1]) setBankChangedUsers(res?.data[1]);
        notify(res?.data?.data || "Success", "info", "", undefined, 20000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Failed to get",
          "error",
          "",
          undefined,
          50000
        );
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.date) {
      errors.date = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <DateInput
                      source="date"
                      validate={[minValue("2021-01-01"), maxValue(currentDate)]}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Check Details
                  </Button>
                </CardActions>
                <Notification />
                {displayAmount.length > 1 ? (
                  <div>
                    <ShowData record={displayAmount} tableCenterAlign={false} />
                  </div>
                ) : null}
                {bankChangedUsers.length > 1 ? (
                  <div>
                    <ShowData
                      record={bankChangedUsers}
                      tableCenterAlign={false}
                    />
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

export default MasterToEwalletFailure;

import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput } from "react-admin";


import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";

import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  tableCell: {
    padding:'0px 10px 5px 10px',
    border:'1px solid gray' 
  },
  button: {
    margin: '1em'
  },
  center: {
    textAlign: 'center'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
}

const { Form } = withTypes<FormValues>();

const PpsPayee = () => {
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const [enableDeletepayee,  setEnableDeletepayee] = useState(false)
  const [payeeRef,  setPayeeRef] = useState('')  
  const [payeeData,  setPayeeData] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [payeeReference, setPayeeReference] = useState([])
  const [enableCreateLenderPayee, setEnableCreateLenderPayee] = useState(true)
  const [enableCreateWithdrawPayee, setEnableCreateWithdrawPayee] = useState(true)

const handleSubmit = (inputData: any) => {
  
  const { email } = inputData;

  let config = getConfigGet({email: email})   

  axios.get(ENDPOINTS.PPS_PAYEE, config)
  .then((res: any) => {
      setUserEmail(email)
      setPayeeData(res.data)
      setEnableCreateLenderPayee(true)
      setEnableCreateWithdrawPayee(true)
      //res.data[0] data from pps
      //res.data[1] data from db

      //---------------get payee reference to display it on dropdown menu----------------
      let payeeReference = res.data[0].filter((payee: any, index: number) => {return index !== 0})
                                      .map((payee: any, index: number) => { 
                                        return ({ id: payee[4], name: payee[4] })
                                      })
      setPayeeReference(payeeReference)

      // ----------Find the accurance of Lender and Withdraw payee,
      //                disable createpayee if payee already present------------
      let payeeList = res.data[0].filter((payee: any, index: number) => {return index !== 0 && payee[4]})
                                      .map((payee: any) => { 
                                          return payee[3] 
                                      })
      const payeeAccurance: any = {};
      for (const payee of payeeList) {
        payeeAccurance[payee] = payeeAccurance[payee] ? payeeAccurance[payee] + 1 : 1;
      }                                                 
        if(payeeAccurance['LENDER PAYEE'] >= 1) setEnableCreateLenderPayee(false)
        if(payeeAccurance['WITHDRAW PAYEE'] >= 1) setEnableCreateWithdrawPayee(false)
        
    })
    .catch((error) => {
      notify(error?.response?.data?.message || 'Get Payee Failed', "error", "", undefined, 50000)
    });

};

const onPayeeRefSelect = (e: any) => {
  setEnableDeletepayee(true)
  setPayeeRef(e.target.value)
}
const DeletePayee = (e: any) => {

    let config = getConfigGet({
        email: userEmail,
        payeeReference: payeeRef
      })   
    axios.delete(ENDPOINTS.PPS_PAYEE, config)
      .then((res: any) => {
        notify('Success', "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Delete Payee Failed', "error", "", undefined, 50000)
      });
      
    };

  const CreatePayee = (payee: string) => {

    let headers = getConfigPost()
    let data = {
      email: userEmail,
      payee: payee
    }

    axios.post(ENDPOINTS.PPS_PAYEE, data, {headers})
      .then((res: any) => {
        notify('Success', "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Create Payee Failed', "error", "", undefined, 50000);
      });
      
    };

   
  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div>
            <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
                <div className={classes.form}>
                <div className={classes.input}>
                    <Field
                    autoFocus
                    name="email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                    />
                </div>
                </div>
                <CardActions className={classes.actions}>
                <Button
                    variant="contained"
                    type="submit"
                    color='primary'
                    disabled={loading}
                    fullWidth
                >
                    {loading && <CircularProgress size={25} thickness={2} />}
                        Get Payee
                </Button>
                </CardActions>
                <Notification />
            </div>
            
            </form>
                {payeeData.length? 
                    <>
                        <p>Data From PPS</p>
                        <ShowData record={payeeData[0]} tableCenterAlign={false} />
                        <p>Data from DB</p>
                        <ShowData record={payeeData[1]} tableCenterAlign={false} />
                        <div className={classes.center}>
                            <SelectInput onChange={onPayeeRefSelect} 
                                      source="Payee Reference" 
                                      choices={payeeReference}
                                      disabled = {(enableCreateWithdrawPayee && enableCreateLenderPayee)} />
                            <Button
                                    color='primary'
                                    variant="contained"
                                    type="submit"
                                    className={classes.button}
                                    onClick={DeletePayee}
                                    disabled = {!enableDeletepayee}
                            >
                                {loading && <CircularProgress size={25} thickness={2} />}
                                    Delete Payee
                            </Button>
                        </div>
                        <div className={classes.center}>
                            <Button
                                color='primary'
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                onClick={() => CreatePayee('Lender Payee')}
                                disabled = {!enableCreateLenderPayee}
                            >
                            {loading && <CircularProgress size={25} thickness={2} />}
                                Create Lender Payee
                            </Button>
                            <Button
                                color='primary'
                                variant="contained"
                                type="submit"
                                className={classes.button}
                                onClick={() => CreatePayee('Withdraw Payee')}
                                disabled = {!enableCreateWithdrawPayee}
                            >
                            {loading && <CircularProgress size={25} thickness={2} />}
                                Create Withdraw Payee
                            </Button>
                        </div>
                    </>
                    : ''}
        </div>
      )}
    />
  );
};


// const ShowData = ({record, tableCenterAlign}: any) => {
//     const classes = useStyles();
//     return (
//         <>
//         <Table style={{display:!tableCenterAlign?'block':'', paddingBottom:'25px'}}>
//             {record.map((r: any, index: number) => (
                
//                 <>
//                   <TableRow>
//                     {r.map((d: any) => (
//                         <TableCell className={classes.tableCell} style={{textAlign:!tableCenterAlign?'center':'left'}}>
//                             {d}
//                         </TableCell>
//                     ))}
//                    </TableRow>
//                 </>
//             ))}
//         </Table>
//         </>
//     )
// }

export default PpsPayee;

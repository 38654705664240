import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Notification,
  useTranslate,
  useNotify,
  downloadCSV,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  search: {
    padding: "10px",
    margin: "16px",
  },
  table: {
    border: "1px solid lightgray",
    borderCollapse: "collapse",
  },
  tdStyle: {
    "& td, th": {
      borderTop: "1px solid lightgray",
      borderBottom: "1px solid lightgray",
      width: "150px",
      height: "30px",
      textAlign: "center",
    },
  },
  inputContainer: {
    display: "flex",
  },
  exportButton: {
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "70px",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  Email?: string;
  FirstName?: string;
  LastName?: string;
  DOB?: string;
  AddressId?: string;
}

const { Form } = withTypes<FormValues>();

const AddressLookup = ({ setAddressForPostCode }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [userSearch, setUserSearch] = useState("");
  const [noMatchingSearchResult, setNoMatchingSearchResult] = useState(false);

  const handleSubmit = (inputData: any) => {
    setAddresses([]);
    setNoMatchingSearchResult(false);
    setUserSearch("");
    notify("pos.fetching_the_result");

    let config = getConfigGet({
      inputData: inputData.postcode,
    });

    axios
      .get(ENDPOINTS.EQUIFAX_ADDRESS, config)
      .then((res: any) => {
        setAddresses(res?.data.data);
        const displayAddress = res?.data.data.map((add: any) => {
          return [add.address_id, add.display_address];
        });
        setAddressForPostCode(displayAddress);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message === "Invalid Signature") {
          notify(error?.response?.data?.message, "error", "", undefined, 50000);
        } else {
          notify("Post code not found", "error", "", undefined, 50000);
        }
      });
  };

  const keys = [
    "address_line_1",
    "address_line_2",
    "town_or_city",
    "county_or_region",
    "postcode",
    "address_id",
  ];
  const search = (data: any) => {
    setNoMatchingSearchResult(false);
    let checkSearch = 0;
    let result = data.filter((item: any) => {
      let temp = keys.some((key) =>
        item[key]?.toLowerCase().includes(userSearch.toLocaleLowerCase())
      );
      if (temp === false) {
        checkSearch++;
      }
      return temp;
    });
    if (checkSearch === data.length) {
      setNoMatchingSearchResult(true);
    }
    return result;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <div style={{ paddingBottom: "5em" }}>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="postcode"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("pos.buttons.post_code")}
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Fetch Address
                  </Button>
                </CardActions>
                {addresses.length ? (
                  <div>
                    <input
                      type="text"
                      placeholder="Search..."
                      className={classes.search}
                      onChange={(e) => setUserSearch(e.target.value)}
                    />
                    <table className={classes.table}>
                      <thead>
                        <tr className={classes.tdStyle}>
                          <th>address_line_1</th>
                          <th>address_line_2</th>
                          <th>town_or_city</th>
                          <th>county_or_region</th>
                          <th>postcode</th>
                          <th>address_id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {search(addresses).map((t: any) => (
                          <tr className={classes.tdStyle} key={t.addressID}>
                            <td>{t.address_line_1}</td>
                            <td>{t.address_line_2}</td>
                            <td>{t.town_or_city}</td>
                            <td>{t.county_or_region}</td>
                            <td>{t.postcode}</td>
                            <td>{t.address_id}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
                {noMatchingSearchResult ? (
                  <p>
                    No matching address found. Please send an email to equifax.
                  </p>
                ) : null}
                <Notification />
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

const ReadCreditReport = ({ setUserData, setcreditReportResult }: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [creditReportResult, setcreditReportResultSub] = useState([]);

  let items = ["Email", "FirstName", "LastName", "DOB", "AddressId"];

  const handleSubmit = (inputData: any) => {
    notify("pos.fetching_the_result");

    let headers = getConfigPost();

    let data = {
      email: inputData.Email,
      first_name: inputData.FirstName,
      last_name: inputData.LastName,
      dob: inputData.DOB,
      address_id: inputData.AddressId,
    };

    axios
      .post(ENDPOINTS.READ_CREDIT_REPORT, data, { headers })
      .then((res: any) => {
        setcreditReportResultSub(res.data);
        setcreditReportResult(res.data);
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "error",
          "error",
          "",
          undefined,
          50000
        );
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.Email) {
      errors.Email = translate("ra.validation.required");
    }
    if (!values.FirstName) {
      errors.FirstName = translate("ra.validation.required");
    }
    if (!values.LastName) {
      errors.LastName = translate("ra.validation.required");
    }
    if (!values.DOB) {
      errors.DOB = translate("ra.validation.required");
    }
    if (!values.AddressId) {
      errors.AddressId = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.inputContainer}>
                    {items.map((item, index) => {
                      return (
                        <div className={classes.input} key={index}>
                          <Field
                            name={item}
                            // @ts-ignore
                            component={renderInput}
                            label={translate(`pos.textInput.${item}`)}
                            disabled={loading}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Get Credit Report
                  </Button>
                </CardActions>
                <p>
                  Note: Get Credit Report will only fecth the latest credit
                  report from equifax, will not update anything w.r.t. user.
                </p>
                {creditReportResult.length > 1 ? (
                  <div>
                    <ShowData
                      record={creditReportResult}
                      tableCenterAlign={false}
                    />
                  </div>
                ) : null}
                <Notification />
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

const AddressAndCreditReport = () => {
  const classes = useStyles();

  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    dob: "",
    address_id: "",
  });
  const [creditReportResult, setCreditReportResult] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const notify = useNotify();

  const setUserDataa = (userData: any) => {
    setUserData(userData);
  };

  const setcreditReportResult = (creditReport: any) => {
    setCreditReportResult(creditReport);
  };

  const setAddressForPostCode = (add: any) => {
    setAddresses(add);
  };

  const customExporter = (records: any) => {
    let address: any = addresses.filter((address: any) => {
      return address[0] === userData.address_id;
    });
    if (!address.length) {
      notify(
        "postcode not matches with the address id",
        "error",
        "",
        undefined,
        50000
      );
      return;
    }
    let csv = "";
    csv =
      csv +
      "Full Name: " +
      userData.first_name +
      userData.last_name +
      "\n" +
      "DOB: " +
      userData.dob +
      "\n" +
      "Address Id: " +
      userData.address_id +
      "\n";
    csv =
      csv + '"Address: ' + address[0][1].split(",").join(",\n\t\t") + '"\n\n';
    csv =
      csv +
      records
        .map((item: any, index: number) => {
          item = item.filter((i: any, index: any) => {
            return index !== item.length - 1;
          });
          return item.join(",");
        })
        .join("\n");
    return downloadCSV(csv, userData.first_name + "_" + userData.last_name);
  };

  return (
    <>
      <Tooltip title="To export the result get credit report and fetch address using postcode">
        <div className={classes.exportButton}>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-46 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
            onClick={() => {
              customExporter(creditReportResult);
            }}
            disabled={
              !creditReportResult.length ||
              !(userData.email.length > 0) ||
              !addresses.length
            }
          >
            <svg
              className="MuiSvgIcon-root RaButton-smallIcon-49"
              viewBox="0 0 24 24"
            >
              <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
            </svg>{" "}
            Export
          </Button>
        </div>
      </Tooltip>
      <AddressLookup setAddressForPostCode={setAddressForPostCode} />
      <ReadCreditReport
        setUserData={setUserDataa}
        setcreditReportResult={setcreditReportResult}
      />
    </>
  );
};

export default AddressAndCreditReport;

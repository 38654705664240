import feathers from 'feathers-client';

import {API,apiKey} from './Constant'

 

export default feathers()
     .configure(feathers.rest(API).fetch(window.fetch.bind(window),{headers: {
          'Content-Type': 'application/json',
          'apiKey':apiKey,
          'Authorization':localStorage.getItem('accessToken'),
          'RefreshToken':localStorage.getItem('refreshToken')
        }}))

    .configure(feathers.hooks())
    .configure(feathers.authentication({apiKey:'sfsafs',scheme:'Bearer',storageKey:'token',header: 'Authorization', jwtStrategy: 'jwt', storage: window.localStorage}));
import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput } from "react-admin";


import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";

import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CustomConfirm from 'ra-custom-confirm';

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  tableCell: {
    padding:'0px 10px 5px 10px',
    border:'1px solid gray' 
  },
  tooltip: {
    padding: '0px'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

interface FormValues {
  email?: string;
  amount? : string;
  select?: string;
  selectRewardsType? : string
}

const { Form } = withTypes<FormValues>();

const TransferRewardAmount = () => {
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [rewardData,  setRewardData] = useState("")
  const [amount, setAmount] = useState(0)
  const [isWithdrawable, setIsWithdrawable] = useState(true)
  const [userEmail, setUserEmail] = useState("")
  const [rewardType, setRewardType] = useState("")
  const [buttonClicked, setButtonClicked] = useState(false)

  const handleSubmit = (inputData: any) => {
    setAmount(0)
    setRewardType("")
    setIsWithdrawable(true)
    const { email, amount, select, selectRewardsType } = inputData;
    if (Number(amount) === 0) {
      notify("invalid amount", "error", "", undefined, 50000);
    } else if (Number(amount) > 0) {
      setRewardData(`This action will transfer £${amount} to Sprive rewards wallet from user's e-wallet.`);
      setUserEmail(email)
      setAmount(amount)
      setIsWithdrawable(Boolean(select))
      setRewardType(selectRewardsType)
      setOpen(true);
    } else {
      let config = getConfigGet({ email: email });
      axios.get(ENDPOINTS.TRANSFER_REWARD_AMOUNT, config)
      .then((res: any) => {
          setUserEmail(email)
          setRewardData(res.data)
          setOpen(true);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed get reward amount/ewallet balance', "error", "", undefined, 50000)
        });
    }

  };

  
  const handleClick = () => {
   if(!buttonClicked){
     setButtonClicked(true); 
     let headers = getConfigPost()
     let data = {
       email: userEmail,
       amountToTransfer: amount,
       isWithdrawable: isWithdrawable,
       rewardsType: rewardType
     }
     axios.post(ENDPOINTS.TRANSFER_REWARD_AMOUNT, data, {headers})
       .then((res: any) => {
         notify('Success', "info", "", undefined, 50000);
       }).catch((error) => {
         notify(error?.response?.data?.message || 'Failed to transfer reward amount', "error", "", undefined, 50000)
       }).finally(() => {
          handelResetFlags()
       })
     }else{
      notify('please wait for one minute between two transactions or refresh the page.', "error", "", undefined, 5000)
     }
      
    };

    const handleDialogClose = () => setOpen(false);
  
  const handelResetFlags = () => {
    setOpen(false);
    setTimeout(()=>{
     setButtonClicked(false)
    }, 60000)
  }
  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if(values.amount){
      const regex = /^[0-9]+([.][0-9]+)?$/;
      setAmount(Number(values.amount))
      if (!regex.test(values.amount)) {
        errors.amount = translate("validation.invalidInput");
      }
      if(values.hasOwnProperty('select')){
        setIsWithdrawable(Boolean(values.select))
        if(Boolean(values.select) === false && !values.selectRewardsType ){
          errors.selectRewardsType = translate("ra.validation.required"); 
        }
      }else{
        errors.select = translate("ra.validation.required");
      }
    }
    // if (values.amount && !values.select) {
    //   errors.select = translate("ra.validation.required");
    // }
    return errors;
  };

  const isAmountWithdrawable = [{ id: 0, name: "rewards amount"}, {id: 1, name: "withdrawable amount"}]
  const rewardsTypeNames = ["promocode_balance", "referral_balance", "giftCard_balance"]
  const rewardsTypeSelect = rewardsTypeNames.map((type: any) => {
                              return ({ id: type, name: type })
                             })
  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  style= {{margin: '0% 0% 10% 30%'}}
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.email")}
                  disabled={loading}
                />
                <div>

                  <Field
                  name="amount"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.amount")}
                  disabled={loading}
                />
                <SelectInput
                    source="select" 
                    style= {{margin: '0px 10px'}}
                    choices={isAmountWithdrawable}
                    disabled={amount === 0} />
                <SelectInput
                    source="selectRewardsType" 
                    style= {{margin: '0px 10px'}}
                    choices={rewardsTypeSelect}
                    disabled={isWithdrawable !== false} />
                </div>
              </div>
            </div>
            <Tooltip
              className={classes.tooltip}
              title="Source: User's E-wallet, Destination: Sprive Rewards Account"
            >
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                    Transfer Reward Amount
                </Button>
              </CardActions>
            </Tooltip>
            <div style={{width: '35em', wordWrap: 'break-word'}}>
              <p>
                Note: If you submit with only email, the full rewards balance from user's E-wallet will get transferred to Sprive rewards wallet.
              </p>
              <p>
                To transfer the specific amount, choose the amount type from the first dropdown, and then, if the <b>rewards amount</b> option is chosen, choose the rewards type. The amount will be debited from withdrawable balance if <b>withdrawable amount</b> is choosen.
              </p>
            </div>
            <Notification />
          </div>
          <CustomConfirm 
            isOpen={open}
            title="Please verify the details"  
            user={rewardData}   
            content={CustomConfirmContent} 
            confirm='OK'                 
            confirmColor='primary'     
            ConfirmIcon={Check}       
            cancel='Cancel'     
            CancelIcon={ErrorOutline}  
            onConfirm={handleClick}
            onClose={handleDialogClose}
          />
        </form>
      )}
    />
  );
};


const CustomConfirmContent = (props:any) => {
  const record = props.user
  return (
    <>
      {typeof(record) === 'string' ? record :
       <ShowData record={record} tableCenterAlign={true} />}
     
    </>
  );

};

export const ShowData = ({record, tableCenterAlign, dataGridStyle}: any) => {
  const classes = useStyles();
        return (
        <>
        <Table style={{display:!tableCenterAlign?'block':'', paddingBottom:'25px'}}>
          <TableBody>
            {record.map((r: any, index: number) => (
               
               <>
                  <TableRow>
                    {r.map((d: any, index: number) => (
                        <TableCell key={d+index} className={dataGridStyle? dataGridStyle : classes.tableCell} style={{textAlign:!tableCenterAlign?'center':'left'}}>
                            {d}
                        </TableCell>
                    ))}
                   </TableRow>
                </>
            ))}
          </TableBody>
        </Table>
        </>
    )
}

export default TransferRewardAmount;

import * as React from 'react';
import { FC } from 'react';

import { useTranslate } from 'react-admin';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CardWithIcon from './CardWithIcon';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';
import LocationOn from '@material-ui/icons/LocationOn';
interface Props {
    value?: any;
    type?: any;
    icon?: any;
}

 
const TotalPayment: FC<Props> = ({ value,type,icon }) => {
    const translate = useTranslate();
    let iconType :any = '';
     if(icon==="calender"){
        iconType=DateRangeIcon;
    }
    else if(icon==="age"){
        iconType=PersonIcon;
    }
    else if(icon==="target"){
        iconType=TrackChangesIcon;
    }
    else if(icon==="home"){
        iconType=AccountBalanceIcon;
    }
    else if(icon==="location"){
        iconType=LocationOn;
    }
    else {
        iconType='';
    }
    return (
        <CardWithIcon
         to={'#'}
            icon={iconType}
            title={translate(type)}
            subtitle={value}
        />
    );
};

export default TotalPayment;

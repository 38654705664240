import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput, DateTimeInput } from "react-admin";


import { ENDPOINTS } from "../apiConstants";
import { getConfigPost } from "../getConfig";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  select: {
    marginLeft: "3em"
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

interface FormValues {
  email?: string;
  SelectAction?: string;
  BookingFrom?: string;
  phoneNumber?: string;
}

const { Form } = withTypes<FormValues>();

const GenerateBrokerReport = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");

  const handleSubmit = (inputData: any) => {
    const { email, SelectAction, BookingFrom, phoneNumber} = inputData;
    const isLifeInsurance = SelectAction === 'Life Insurance Booking' ? true : false;
    let headers = getConfigPost()
    let data = {
      email: email,
      isLifeInsurance: isLifeInsurance,
      bookingFrom: moment(BookingFrom).utc().add(5, "hours").add(30, "minutes"),
      phoneNumber: phoneNumber ? phoneNumber : 'NA'
    }

    axios({
      method: "post",
      url: ENDPOINTS.GENERATE_BROKER_REPORT,
      headers: headers,
      data: data,
    })
      .then((res: any) => {
        setSpreadsheetUrl(res.data[0]);
        notify(res?.data?.data || 'Success', "info", "", undefined, 50000);
        setLoading(false);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Failed to generate broker report', "error", "", undefined, 50000)
      });

  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.SelectAction) {
        errors.SelectAction = translate("ra.validation.required");
    }
    if (!values.BookingFrom) {
        errors.BookingFrom = translate("ra.validation.required");
    }
    if (values.phoneNumber){
      const regex = /^[0-9\s+]*$/;
      if (!regex.test(values.phoneNumber)) {
        errors.phoneNumber = translate("validation.invalidInput");
      }
    }
    return errors;
  };
  let SelectAction = ["Mortgage Booking", "Life Insurance Booking"]
  let SelectActionOption = SelectAction.map((code: any) => {
    return { id: code, name: code };
  });

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <div className={classes.form}>
              <div className={classes.input}>
                <div style={{margin: "2em 15em"}}>
                <Field
                  autoFocus
                  name="email"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.email")}
                  disabled={loading}
                />
                </div>
                 <SelectInput className={classes.select}
                              source="SelectAction"
                              choices={SelectActionOption}
                          />
                 <DateTimeInput className={classes.select} source="BookingFrom" />
                 <Field className={classes.select}
                  name="phoneNumber"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.phoneNumber")}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color='primary'
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                  Generate Broker Report
                </Button>
            </CardActions>
            {spreadsheetUrl.length ? <div style={{width: '35em', wordWrap: 'break-word'}}><a href={spreadsheetUrl} target="_blank" rel="noopener noreferrer">{spreadsheetUrl}</a></div> : ""}
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default GenerateBrokerReport;

import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import {
  Notification,
  useTranslate,
  useNotify
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  search: {
    padding: "10px",
    margin: "16px",
  },
  button: {
    margin: "1em 1em 25% 1em",
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  Email?: string;
}

const { Form } = withTypes<FormValues>();

const DeleteUser = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [activityPerformed, setActivityPerformed] = useState([]);
  const [tablesToUpdate, setTablesToUpdate] = useState([]);
  const [updateTableData, setUpdateTableData] = useState([])
  const [userEmail, setUserEmail] = useState([]);

  const handleSubmit = (inputData: any) => {
    setActivityPerformed([]);
    setUserEmail(inputData.Email);
    notify("pos.fetching_the_result");

    let config = getConfigGet({ email: inputData.Email });

    axios
      .get(ENDPOINTS.DELETE_USER, config)
      .then((res: any) => {
        setLoading(false);
        setActivityPerformed(res?.data[0]);
        setTablesToUpdate(res?.data[1]);
        setUpdateTableData(res?.data[2])
        notify("Success", "info", "", undefined, 20000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Failed to get",
          "error",
          "",
          undefined,
          50000
        );
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.Email) {
      errors.Email = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="Email"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("pos.email")}
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    Get Deletion Data
                  </Button>
                </CardActions>
                <Notification />
              </div>
            </form>
            <DeletionData
              userEmail={userEmail}
              activityPerformed={activityPerformed}
              tablesToUpdate={tablesToUpdate}
              updateTableData = {updateTableData}
            />
          </div>
        )}
      />
    </>
  );
};

const DeletionData = ({ userEmail, activityPerformed, tablesToUpdate, updateTableData}: any) => {
  const classes = useStyles();
  const notify = useNotify();

  const deleteAccountOnClick = (e: any) => {
    let headers = getConfigPost();
    let data = {
      email: userEmail,
      updateTableData: updateTableData
    };

    axios
      .post(ENDPOINTS.DELETE_USER, data, { headers })
      .then((res: any) => {
        notify(`Success: `, "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || `Failed `,
          "error",
          "",
          undefined,
          50000
        );
      });
  };

  return (
    <>
      <div style={{ paddingLeft: "25%" }} >
        {activityPerformed.length > 1 ? (
          <div>
            <ShowData record={activityPerformed} tableCenterAlign={false} />
          </div>
        ) : null}

        <div>
          {tablesToUpdate.length ? (
            <div>
              <>
                <ul style={{ display: "inline-block" }}>
                  {tablesToUpdate.map((detail: any, index: number) => (
                    <li>{detail}</li>
                  ))}
                </ul>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  onClick={deleteAccountOnClick}
                >
                  Delete Account
                </Button>
              </>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DeleteUser;

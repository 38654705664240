import * as React from 'react';
import { FC } from 'react';
 
import {
    // Datagrid,
    // TextField,
    List,
    // NumberField,
    // EmailField,
    ListProps,
    useListContext,
    Responsive,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar} from 'react-admin';
 
 

 
 
const ListActions = (props:any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
   
    const {
        currentSort,
        resource,
        filterValues,
        total,
    } = useListContext();
   
  
    // window.location.reload()
    return ( 
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
               
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            {/* Add your custom actions */}
             
        </TopToolbar>

     );
};
 


 

const GridList: FC<ListProps> = (props: any) => {
    
   
    
    let pperPage = 5;
   
  
    let sort = {
        field: 'id', order: 'DESC'
    };
    // const postRowClick = (props:any) => {
    //     return "";
    // }
    
    return (
        <>
        {/* Add your custom actions */}
        <Responsive medium={
       
        <List
        className="grid-list"
        bulkActionButtons={false}
        // bulkActionButtons={<PostBulkActionButtons />}
        title={'Lender Payments'}
        sort={{ field: 'mode', order: 'ASC' }}
        actions={<ListActions sort={sort} />}
            {...props}
             {...sort}
            // sort={sort}

             perPage={pperPage}
        >
            <>            
             
               {/* <Datagrid rowClick={postRowClick}   hasBulkActions={false}  >
                    <TextField label={"Sl No"} source="id" />
                    <TextField label={"User Id"} source="user_id" />
                    <TextField label={"User Mode"} source="mode" />
                    <EmailField label={"Email"} source="email" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Amount"} source="amount" />
                    <TextField sortable={true} label={"Lender"} source="lender_name" />
                    <TextField sortable={true} label={"Lender Acc No"} source="lender_account_no" />
                    <TextField sortable={true} label={"Lender Sort Code"} source="lender_sort_code" />
                    <TextField sortable={true} label={"Payment Reference"} source="payment_reference" />
                    <TextField sortable={true} label={"Transaction Date"} source="transaction_date" />
                </Datagrid>   */}
                
            </>
           
        </List>
        } />
        </>
    );
};

export default GridList;

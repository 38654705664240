//import { Label } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
 
import {
    // Datagrid,
    // TextField,
    List,
    // EmailField,
    useListContext,
    Responsive,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar
} from 'react-admin';
 
const ListActions = (props:any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        filterValues,
        total,
    } = useListContext();
   

    // window.location.reload()
    return ( 
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>

            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            {/* Add your custom actions */}
             
        </TopToolbar>

     );
};

const GridList: FC<any> = (props: any) => {
    let pperPage = 5;
   
  
    let sort = {
        field: 'id', order: 'DESC'
    };
   
    // const postRowClick = (props:any) => {
    //     return "";
    // }

    return (
        <>
        {/* Add your custom actions */}
        <Responsive medium={
           <List
                className="grid-list"
                bulkActionButtons={false}
                // bulkActionButtons={<PostBulkActionButtons />}
                title={'User Session Info'}
                actions={<ListActions sort={sort} />}
                {...props}
                sort={sort}
                perPage={pperPage}>
               <>
               {/* <Datagrid rowClick={postRowClick}   hasBulkActions={false}  >
                    <TextField label={'Mode'} source="user_mode" />
                    <EmailField label={"Email"} source="email" />
                    <TextField label={'Name'} source="full_name" />
                    <TextField label={'isOnBoarded'} source="is_onboarded" />
                    <TextField
                               label={'Last Used'} source="last_used"  />
                    <TextField label={'Device Id'} source="device_id" />
                    <EmailField label={"Last Screen Name"} source="last_screen_name" />
                    <TextField label={'Device Model'} source="device_model" />
                    <TextField label={'OS'} source="os_platform" />
                    <TextField
                               label={'Last Debit'} source="last_debit"  />
                    <TextField
                               label={'E-Wallet Payment'} source="ewallet_payment"  />
                    <TextField
                               label={'Manual Payment'} source="manual_payment"  />
                </Datagrid> */}
            </>
        </List>
        } />
        </>
    );
};

export default GridList;



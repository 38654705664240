import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        email: 'email',
        search: 'Search',
        amount: 'Amount',
        phoneNumber: 'Phone Number',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Avg Lender Overpayment',
            avg_age: 'Avg User Age',
            avg_balance: 'Avg Mortgage Balance',
            avg_home_val: 'Avg Home Valuation',
            avg_payment: 'Average Monthly Fixed Payment ',
            avg_terms: 'Avg Mortgage Term',
            total_payment: 'Total Lender Overpayment',
            creation_pending_bps_payer:'Directs Debit : Creation Pending',
            active_bps_payer:'Direct Debits : Active',
            registered_users: 'Registered Users',
            onboarded_users:'Activated Users',
            ewallet_users:'Auto Mode Users',
            manual_users:'Manual Mode Users',
            last_used_less_than_thirty:'Last Active < 30 Days',
            no_payment: 'No of users overpaid',
            total_active:'Direct Debits : Total Active',
            pending_reviews: 'Issues',
            top_ledger: 'Top Lenders',
            top_cities: 'Top Cities',
            total_mortgage_balance: 'Total Mortgage Supported',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            grid: 'Grid',
            users: 'Users',
            Issues: 'Issues',
        },
        buttons: {
            email_or_user_id : 'Email Or User Id or PPS Account Number',
            home_valuation : 'Home Valuation',
            post_code : 'Post Code'
        },
        textInput: {
            Email: 'Email',
            FirstName : 'FirstName',
            LastName: 'LastName',
            DOB: 'DOB',
            AddressId: 'AddressId',
            Title: 'Title',
            Message: 'Message'
        },
        fetching_the_result : 'Fetching the search result.',
        client_reference_id: 'Client Reference Id'
     },
    resources: {
        userDetail:{
            name: 'User Details |||| User Details'
        },
        accountClosure: {
            name: 'Account closure |||| Account closure',
        },
        homeValuation: {
            name: 'Home Valuation |||| Home Valuation',
        },
        promocodeRecords:{
            name: 'Promo Codes |||| Promo Codes',
        },
        giftCardDashboard:{
            name: 'Gift Card Dashboard |||| Gift Card Dashboard',
        },
        applyPromoReferralCode:{
            name: 'Apply Promo & Ref Code |||| Apply Promo & Ref Code'
        },
        transferRewardAmount:{
            name: 'Transfer Rewards Balance |||| Transfer Rewards Balance',
        },
        updatePayToLender: {
            name: 'Toggle Lender Payments |||| Toggle Lender Payments'
        },
        updateDirectDebit: {
            name: 'Toggle Auto Save |||| Toggle Auto Save'
        },
        cancelGiftCard: {
            name: 'Cancel Gift Card |||| Cancel Gift Card'
        },
        addressAndCreditReport:{
            name: 'Address And Credit Report |||| Address And Credit Report',
        },
        pullCreditFile:{
            name: 'Update Equifax Data |||| Update Equifax Data'
        },
        generateBrokerReport:{
            name: 'Generate Broker Report |||| Generate Broker Report'
        },
        pnNotifier:{
            name: 'PN Notifier |||| PN Notifier'
        },
        remortgageLetter:{
            name: 'Remortgage Letter |||| Remortgage Letter'
        },
        grid:{
            name: 'Download User List |||| Download User List',
        },
        debitCalculator: {
            name: 'Download Debit Calculator |||| Download Debit Calculator',
        },
        userSessionInfo: {
            name: 'Download User Session Info |||| Download User Session Info',
        },
        userPayments:{
            name: 'Download Lender Payments |||| Download Lender Payments',
        },
        masterToEwalletFailure:{
            name: 'Master To Ewallet Failure |||| Master To Ewallet Failure'
        },
        redisCacheCleaner:{
            name: 'Redis Cache Cleaner |||| Redis Cache Cleaner'
        },
        updatePendingTask: {
            name: 'Update Pending Task |||| Update Pending Task'
        },
        updateUserPendingAction: {
            name: 'Update User Pending Action |||| Update User Pending Action'
        },
        getVerificationLink: {
            name: 'Get Verification Link |||| Get Verification Link'
        },
        updateGiftCardBlackList: {
            name: 'Update Giftcard Blacklist |||| Update Giftcard Blacklist'
        },
        ppsPayee:{
            name: 'PPS Payee |||| PPS Payee'
        },
        deleteUser:{
            name: 'Delete User |||| Delete User'
        },
        manageUser: {
            name: "Manage User |||| Manage User"
        },
        users: {
            name: 'Users |||| Users',
            fields: {
                commands: 'Orders',
                name: 'Name',
                has_open_banking: 'Open Banking',
                is_pin_enabled: 'Pin',
                is_blocked: 'Blocked',
                is_verified: 'Verified',
                is_face_id_enabled: 'Face ID',
                dob: 'Birthdate',
                email: 'Emails',
                updatedAt: 'Last Update',
                createdAt: 'Created At',
                permissions: 'Role',
            },
            fieldGroups: {
                identity: 'User',
                updatedAt: 'Last Update',
                has_open_banking: 'Has Open Banking',
                permissions: 'Permissions',
                block_comments: 'block comments',
                address: 'Address',
                is_verified: 'is verifed',
                is_pin_enabled: 'is pin enabled',
                is_face_id_enabled: 'is face id enabled',
                push_notification_id: 'Push notification id',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            filters: {
                updatedAt:'updatedAt',
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                is_verified: 'Verified',
                permissions: 'Permission',
                group: 'Segment',
            },
        },
        issues: {
            name: 'Issues |||| Issues',
            fields: {
                name: 'Name',
                category: 'Category',
                updatedAt: 'Last Update',
                createdAt: 'Created At',
            },
            fieldGroups: {
                identity: 'User',
                updatedAt: 'Last Update',
                history: 'History',
            },
            filters: {
                updatedAt:'updatedAt',
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                is_verified: 'Verified',
                permissions: 'Permission',
                group: 'Segment',
            },
        },
    },
    buttons: {
        EmailOrIds : "Email Or User Id"
    },
    validation: {
        invalidInput: "Invalid Input"
    }
};

export default customEnglishMessages;

import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="47pt" height="34pt" viewBox="0 0 2319 288.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Sprive Admin
</metadata>
<g transform="translate(0.000000,603) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M22474 7870 c-28 -11 -54 -48 -54 -77 0 -13 32 -83 70 -155 39 -72
70 -135 70 -139 0 -4 -208 -118 -463 -253 -255 -135 -471 -252 -481 -259 -16
-11 -10 -21 64 -107 45 -52 90 -102 100 -110 10 -8 23 -26 30 -38 6 -13 14
-21 17 -19 6 6 73 -67 73 -79 0 -5 18 -27 40 -49 21 -22 39 -47 40 -55 0 -9 5
-21 11 -27 25 -25 88 -151 95 -190 3 -14 7 -30 10 -35 10 -16 -23 -7 -176 46
-85 30 -213 75 -284 99 -103 36 -136 53 -168 84 -62 59 -82 106 -83 188 0 88
31 154 95 204 25 18 38 32 29 30 -9 -2 -146 -74 -304 -159 -247 -133 -297
-164 -349 -215 -141 -139 -196 -343 -146 -539 39 -152 133 -278 261 -349 35
-19 292 -114 572 -212 279 -98 507 -181 507 -184 0 -3 -199 -112 -443 -241
-412 -219 -447 -239 -508 -300 -97 -96 -138 -195 -139 -330 0 -186 97 -343
265 -426 69 -34 84 -38 178 -42 76 -3 118 0 158 11 55 17 1696 887 1780 944
275 189 310 612 70 852 -37 38 -92 80 -126 98 -33 17 -288 111 -567 209 -280
98 -508 181 -508 184 1 3 181 100 400 216 l400 212 71 -137 c40 -75 81 -146
92 -158 25 -28 85 -31 115 -5 15 12 65 165 187 568 178 591 176 584 123 618
-20 12 -1010 316 -1078 330 -14 3 -35 1 -46 -4z m272 -2843 c37 -20 87 -72
110 -117 28 -53 26 -162 -5 -223 l-24 -49 -81 149 c-45 81 -101 183 -124 225
l-43 77 69 -23 c37 -13 81 -30 98 -39z"/>
<path d="M11110 5911 c-91 -28 -183 -112 -216 -196 -16 -43 -19 -77 -19 -245
0 -193 0 -196 28 -251 32 -66 99 -132 165 -162 44 -20 63 -22 262 -22 211 0
216 1 270 26 66 31 131 94 162 157 21 43 23 62 26 243 3 181 2 199 -19 254
-30 80 -104 154 -182 183 -52 20 -76 22 -255 21 -108 0 -208 -4 -222 -8z"/>
<path d="M1570 5613 c-822 -60 -1405 -509 -1459 -1123 -10 -115 1 -267 29
-374 92 -362 421 -629 1021 -830 164 -54 327 -98 641 -170 649 -150 939 -280
1030 -465 19 -38 23 -62 23 -146 0 -88 -3 -107 -27 -157 -70 -150 -254 -253
-519 -292 -135 -20 -412 -21 -539 -1 -359 54 -601 220 -744 510 -38 78 -91
228 -110 318 -4 17 -13 27 -24 27 -25 0 -876 -172 -883 -179 -11 -10 28 -174
70 -300 77 -231 196 -431 357 -600 303 -318 729 -506 1273 -561 156 -15 524
-12 681 6 642 74 1093 336 1291 749 142 297 143 678 3 974 -154 327 -457 551
-994 735 -155 53 -303 92 -825 216 -552 131 -784 239 -824 383 -29 102 7 213
96 299 134 130 349 198 622 198 216 -1 366 -32 526 -110 223 -109 363 -277
458 -552 4 -10 12 -18 19 -18 7 0 208 46 446 102 l434 103 -6 39 c-4 22 -25
87 -47 145 -254 674 -965 1090 -1844 1079 -71 -1 -150 -3 -175 -5z"/>
<path d="M5890 4599 c-300 -35 -575 -148 -757 -313 l-53 -48 0 141 0 141 -430
0 -430 0 0 -2260 0 -2260 450 0 450 0 0 796 0 796 41 -36 c124 -112 348 -211
610 -268 92 -20 133 -22 344 -23 215 0 251 2 349 24 118 25 260 71 367 119
262 118 521 349 679 607 295 479 329 1150 85 1670 -239 511 -715 849 -1290
915 -105 12 -307 11 -415 -1z m287 -804 c184 -39 367 -129 477 -234 98 -93
149 -203 187 -403 28 -147 23 -371 -10 -488 -88 -305 -303 -516 -610 -597
-132 -36 -342 -37 -474 -5 -316 78 -547 280 -612 537 -18 72 -22 552 -4 637 6
29 29 90 51 135 107 220 373 393 663 432 87 12 242 6 332 -14z"/>
<path d="M17556 4599 c-290 -31 -565 -129 -806 -290 -122 -81 -323 -281 -407
-404 -217 -321 -316 -714 -285 -1127 20 -251 70 -452 162 -644 218 -454 627
-743 1195 -845 116 -21 160 -24 415 -24 228 0 306 4 391 18 331 56 606 170
848 352 101 76 281 243 281 260 0 6 -123 133 -274 284 l-274 274 -102 -92
c-141 -128 -245 -203 -364 -261 -197 -97 -408 -134 -632 -111 -161 16 -259 42
-384 103 -92 44 -116 61 -190 137 -64 64 -95 105 -122 161 -39 80 -68 178 -68
229 l0 31 1280 0 1280 0 0 198 c0 279 -21 429 -87 637 -203 632 -728 1044
-1422 1114 -118 12 -319 12 -435 0z m529 -752 c50 -14 113 -36 140 -48 192
-85 342 -253 389 -435 9 -34 16 -67 16 -73 0 -8 -233 -11 -840 -11 -727 0
-840 2 -840 14 0 8 11 50 25 94 65 207 232 364 468 442 124 41 200 50 382 46
144 -3 184 -8 260 -29z"/>
<path d="M9792 4569 c-245 -33 -478 -133 -651 -280 l-81 -70 0 151 0 150 -420
0 -420 0 0 -1585 0 -1585 445 0 445 0 0 738 c0 419 5 789 10 857 51 586 360
862 904 806 64 -7 161 -23 214 -36 53 -13 98 -23 99 -22 3 4 183 780 183 792
0 14 -102 44 -240 70 -102 20 -384 28 -488 14z"/>
<path d="M10890 2935 l0 -1585 445 0 445 0 0 1585 0 1585 -445 0 -445 0 0
-1585z"/>
<path d="M12188 4383 c37 -76 390 -789 783 -1585 l714 -1448 411 0 410 0 787
1578 c433 868 787 1582 787 1585 0 4 -223 6 -495 5 l-495 -3 -493 -1062 c-270
-585 -494 -1063 -497 -1063 -3 0 -104 213 -224 473 -120 259 -343 739 -494
1065 l-275 592 -493 0 -494 0 68 -137z"/>
</g>
</svg>
);

export default Logo;

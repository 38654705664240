import * as React from 'react';
import { FC } from 'react';
 
import {
    // Datagrid,
    // TextField,
    List,
    // NumberField,
    // EmailField,
    ListProps,
    useListContext,
    Responsive,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar} from 'react-admin';
 
 

 
 
const ListActions = (props:any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
   
    const {
        currentSort,
        resource,
        filterValues,
        total,
    } = useListContext();
   
    return ( 
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
               
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
             
        </TopToolbar>

     );
};
 


 

const GridList: FC<ListProps> = (props: any) => {
    
   
    //number of rows to display per page
    let pperPage = 5;
   
  
    let sort = {
        field: 'id', order: 'DESC'
    };
    // const postRowClick = (props:any) => {
    //     console.log(props)
    //     return "";
    // }
    
    return (
        <>
        {/* Add your custom actions */}
        <Responsive medium={
       
        <List
        className="grid-list"
        bulkActionButtons={false}
        title={'User List'}
        sort={{ field: 'user_mode', order: 'ASC' }}
        actions={<ListActions sort={sort} />}
            {...props}
             {...sort}
             perPage={pperPage}
        >

            <></> 
                {/*<Datagrid rowClick={postRowClick}   hasBulkActions={false}  >
                    <TextField label={"Id"} source="id" />
                    <TextField label={"User Mode"} source="user_mode" />
                    <TextField label={"Name"} source="full_name" />
                    <EmailField label={"Email"} source="email" />
                    <TextField label={"Is On-Boarded"} source="is_onboarded" />
                    <TextField label={"Is Email Verified"} source="is_email_verified"/>
                    <TextField label={"Payment Reference"} source="payment_reference"/>
                    <TextField label={"Linked Bank"} source="linked_bank"/>
                    <TextField label={"DD Status"} source="dd_status" />
                    <TextField label={"Registration Date"} source="register_dt" />
                    <TextField label={"E-wallet Registration Date"} source="e_wallet_creation_dt" />
                    <TextField sortable={true} label={"Lender"} source="lender" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Min"} source="min" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Max"} source="max" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Range"} source="range" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Mortgage Balance"} source="mortgage_balance" />
                    <NumberField label={"Term (yrs)"} source="mortgage_term" />
                    <NumberField label={"Term (mths)"} source="mortgage_term_months" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Monthly Payment"} source="fixed_payment_month" />
                    <NumberField label={"Age"} source="age" />
                    <TextField sortable={false} label={"Address 1"} source="address1" />
                    <TextField sortable={false} label={"Address 2"} source="address2" />
                    <TextField label={"City"} source="city" />
                    <TextField sortable={true} label={"Post Code"} source="postcode" />
                    <NumberField options={{ style: 'currency', currency: 'GBP',maximumFractionDigits: 2 }} label={"Home Valuation"} source="home_valuation" />
                    <NumberField options={{ style: 'percent',maximumFractionDigits: 2  }} label={"LTV"} source="ltv" />
                    <NumberField label={"Goal"} source="goal" />
                    <NumberField label={"OS Platform"} source="os_platform" />
                    <NumberField label={"Referred By"} source="referred_by" />
                    <NumberField label={"Promo Code"} source="promo_code" />
                    <NumberField label={"Deal Expiry Date"} source="deal_expiry_date" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Avg Overpayment"} source="avg_overpayment" />
                    <NumberField options={{ style: 'currency', currency: 'GBP' }} label={"Total Overpayment"} source="total_overpayment" />
                    <NumberField label={"No. of Overpayments"} source="no_overpayment" />
                    <TextField label={"On Target"} source="target" />
                    <TextField   label={"Sort Code"} source="short_code" />
                    <TextField  label={"Account No"} source="account_no" />
                    <TextField sortable={false} label={"Payment Reference"} source="payment_reference" />
                   
                </Datagrid>  */}
           
        </List>
        } />
        </>
    );
};

export default GridList;

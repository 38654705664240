import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Notification,
  useTranslate,
  useNotify,
  SelectInput,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigPost } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  search: {
    padding: "10px",
    margin: "16px",
  },
  table: {
    border: "1px solid lightgray",
    borderCollapse: "collapse",
  },
  tdStyle: {
    "& td, th": {
      borderTop: "1px solid lightgray",
      borderBottom: "1px solid lightgray",
      width: "150px",
      height: "30px",
      textAlign: "center",
    },
  },
  inputContainer: {
    display: "flex",
  },
  exportButton: {
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "70px",
  },
  select: {
    paddingLeft: "0.5em"
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);




const CreateAdminPanelUser = () => {
  
  interface FormValues {
    Email?: string;
    Role?: string;
  }
  const { Form } = withTypes<FormValues>();

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const handleSubmit = (inputData: any) => {
    notify("Creating the user");
    let headers = getConfigPost();
    let data = {
      email: inputData.Email,
      role: inputData.Role,
    };

    axios
      .post(ENDPOINTS.MANAGE_USER, data, { headers })
      .then((res: any) => {
        notify(`User Created`, "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(
          error?.response?.data?.message || "Failed to add promocode",
          "error",
          "",
          undefined,
          50000
        );
      });
  };


  let roles = ["admin", "tech", "ops"];
  let rolesOption = roles.map((code: any) => {
    return { id: code, name: code };
  });


  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.Email) {
      errors.Email = translate("ra.validation.required");
    }
    if (!values.Role) {
      errors.Role = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div style={{ paddingBottom: "5em" }}>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      name="Email"
                      // @ts-ignore
                      component={renderInput}
                      label={translate("pos.email")}
                    />
                    <SelectInput className={classes.select}
                      source="Role"
                      choices={rolesOption}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    Create Admin Panel User
                  </Button>
                </CardActions>
                <Notification />
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

const UpdateAdminPanelUser = () => {

  interface FormValues {
    Email?: string;
    SelectAction?: string;
    Action? : string
  }
  const { Form } = withTypes<FormValues>();

  const classes = useStyles();
  const notify = useNotify();

  const translate = useTranslate(); 
  const [action, setaction] = useState([{id: '', name: ''}])


  let SelectAction = ["Change Role", "User Access"]
  let SelectActionOption = SelectAction.map((code: any) => {
    return { id: code, name: code };
  });

  let roles = ["admin", "tech", "ops", "marketing"];
  let block = ["Provide Access", "Remove Access"];

  const onSelectaction = (sel: any) => {
    let action: String[] = sel.target.value === SelectAction[0] ? roles : block
    setaction(action.map((task: any) => { return ({ id: task, name: task}) }))
  }

  
  const handleSubmit = (inputData: any) => {
    notify("Updating the user");

    let headers = getConfigPost()
    let role = inputData.SelectAction === SelectAction[0] ? inputData.Action : ""
    let is_blocked = inputData.SelectAction === SelectAction[1] ? inputData.Action === 'Remove Access' ? 1 : 0 : ""

    let data = {    
      role: role,
      is_blocked: is_blocked
    }
    let params = {
        email: inputData.Email
    }
    
    axios.patch(ENDPOINTS.MANAGE_USER, data, {headers, params})
    .then((res: any) => {
      notify(`success`, "info", "", undefined, 50000);
    })
    .catch((error) => {
      notify(error?.response?.data?.message || `Failed `, "error", "", undefined, 50000)
    });
};

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.Email) {
      errors.Email = translate("ra.validation.required");
    }
    if (!values.SelectAction) {
      errors.SelectAction = translate("ra.validation.required");
    }
    if (!values.Action) {
      errors.Action = translate("ra.validation.required");
    }
    return errors;
  };


  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <div className={classes.form}>
                  <div className={classes.inputContainer}>
                        <div className={classes.input}>
                          <Field
                            name={"Email"}
                            // @ts-ignore
                            component={renderInput}
                            label={translate("pos.email")}
                          />
                          <SelectInput className={classes.select}
                              onChange={onSelectaction} 
                              source="SelectAction"
                              choices={SelectActionOption}
                          />
                          <SelectInput className={classes.select}
                            source="Action"
                            choices={action}
                            disabled = {!action[0].id.length}
                          />
                        </div>
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    Update Admin Panel User
                  </Button>
                </CardActions>
               
         
                <Notification />
              </div>
            </form>
          </div>
        )}
      />
    </>
  );
};

const ManageUser = () => {
  return (
    <>
      <CreateAdminPanelUser />
      <UpdateAdminPanelUser/>
    </>
  );
};

export default ManageUser;

import * as React from "react";
import { useEffect, useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Notification,
  useTranslate,
  useNotify,
  SelectInput,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
    minWidth: "30em",
  },
  selectIput: {
    margin: "1em",
  },
  moveRight: {
    paddingLeft: "30%",
    margin: "1.5em 1.5em 0em 1.5em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  title?: string,
  message?: string,
  screenName?: string,
  category?: string,
  target?: string,
  type?: string
}

const { Form } = withTypes<FormValues>();

function PNNotifier() {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [csvOutput, setCsvOutput] = useState([]);
  const [testPushNotificationEmail, setTestPushNotificationEmail] = useState("");
  const [screenNameOptions, setScreenNameOptions] = useState([
    { id: "", name: "" },
  ]);
  const [notificationCategoryOptions, setNotificationCategoryOptions] =
    useState([{ id: "", name: "" }]);
  const [notificationTargetOptions, setNotificationTargetOptions] = useState([
    { id: "", name: "" },
  ]);
  const [notificationTypeOptions, setNotificationTypeOptions] = useState([
    { id: "", name: "" },
  ]);
  const [isTest, setIsTest] = useState(false);

  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    if (e.target.files[0]) {
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = function (event: any) {
        const csvOutput = event.target.result;
        let temp = csvOutput.split("\n");
        temp = temp.filter((t: any) => {
          return t !== "";
        });
        setCsvOutput(temp);
      };
    }
  };

  const onTestRun = () => {
    setIsTest(true);
  };
  const handleSubmit = (inputData: any) => {
    let currentCsv: string[] = csvOutput;
    if (isTest) {
      currentCsv = [testPushNotificationEmail];
    } else if (!csvOutput.length) {
      notify("select a csv file", "error", "", undefined, 50000);
      return;
    }
    let chunkArr = chunkArray(currentCsv, 100)  

    for(let i in chunkArr){
      let headers = getConfigPost();
      let data = {
        title: inputData.title,
        message: inputData.message,
        pushNotificationEmails: chunkArr[i],
        screenName: inputData.screenName,
        category: inputData.category,
        target: inputData.target,
        type: inputData.type,
      };
  
      axios
        .post(ENDPOINTS.PN_NOTIFIER, data, { headers })
        .then((res: any) => {
          notify(`success`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(
            error?.response?.data?.message || "error",
            "error",
            "",
            undefined,
            50000
          );
        })
        .finally(() => {
          setIsTest(false);
        });
      }
  };

  useEffect(() => {
    
    const config = getConfigGet({});
    const dataRes = async () =>
      axios
        .get(ENDPOINTS.PN_NOTIFIER, config)
        .then((res: any) => {
          setScreenNameOptions(
            res.data[0].screenName.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setNotificationCategoryOptions(
            res.data[0].category.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setNotificationTargetOptions(
            res.data[0].target.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setNotificationTypeOptions(
            res.data[0].type.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setTestPushNotificationEmail(res.data[0].testPushNotificationEmail);
        })
        .catch((error) => {
          notify(error?.response?.data?.message, "error", "", undefined, 50000);
        });

    dataRes();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.title) {
      errors.title = translate("ra.validation.required");
    }
    if (!values.message) {
      errors.message = translate("ra.validation.required");
    }
    if (!values.screenName) {
      errors.screenName = translate("ra.validation.required");
    }
    return errors;
  };

  if (!screenNameOptions[0].name.length) return <div>Waiting for data...</div>;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    name="title"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.textInput.Title")}
                  />
                  <Field
                    name="message"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.textInput.Message")}
                  />
                  <input
                    className={classes.moveRight}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    onClick={handleOnChange}
                  />
                  <p>
                    Upload a <b>CSV</b> file. Keep all <b>Email</b> in the A1 column.
                    Refresh the page before re-uploading the file.
                  </p>
                  {/* <div className={classes.moveRight}>
                    <CustomDropDown
                      setCustomDropdownResult={setCustomDropdownResult}
                      screenNameOptions={screenNameOptions}
                      sourceName="screenName"
                    />
                  </div> */}
                  <SelectInput
                    className={classes.selectIput}
                    source="screenName"
                    choices={screenNameOptions}
                  />
                  <SelectInput
                    className={classes.selectIput}
                    source="category"
                    choices={notificationCategoryOptions}
                  />
                  <SelectInput
                    className={classes.selectIput}
                    source="target"
                    choices={notificationTargetOptions}
                  />
                  <SelectInput
                    className={classes.selectIput}
                    source="type"
                    choices={notificationTypeOptions}
                  />
                </div>
              </div>
              <Notification />
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={onTestRun}
                >
                  Send Test Notification
                </Button>
                <Button variant="contained" type="submit" color="primary">
                  Send Notification
                </Button>
              </CardActions>
            </div>
          </form>
        </>
      )}
    />
  );
}

export function chunkArray(array: any, chunkSize: any) {
  return Array.from(
      { length: Math.ceil(array.length / chunkSize) },
      (_, index) => array.slice(index * chunkSize, (index + 1) * chunkSize)
  );
}

export default PNNotifier;

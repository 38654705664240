import { Button, CardActions, FormGroup, TableCell, TableRow, TextField } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { SelectInput, useNotify } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import { Field, withTypes } from "react-final-form";
import { getConfigPost } from "../getConfig";
import axios from "axios";
import ENDPOINTS from "../apiConstants";

const useStyles = makeStyles((theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em"
    },
    keyValue: {
        marginLeft:"35%",
        display: "inherit"
    },
    keyValueField: {
        marginLeft: "1em"
    },
    tableCell: {
        padding:'0px 10px 5px 10px',
        border:'1px solid gray'
      }
  }));

  

  const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
    />
  );

  const RedisCacheCleaner = () => {
   
    const classes = useStyles();
    const notify = useNotify();
    
    const [disableButton, setDisableButton] = useState(true);
    const [selectedInput,  setSelectedInput] = useState('Select Something')
    const [delectKeys,  setDelectKeys] = useState(false)
    const [addKeys,  setAddKeys] = useState(false)
    const [keyValueToSet,  setKeyValueToSet] = useState([0,1])
    const [counter,  setCounter] = useState(2) // because 0 and 1 indexes are set by defalut keyValueToSet
    const handleSubmit = (e: any) =>{
        let data
        if(e.category === 'Delete All Keys'){
            data = { delete_all: 'true' }    
        }
        if(e.category === 'Delete Keys'){
            data = { delete_keys: e.user_ids }   
        }
        if(e.category === 'Add Keys'){
            let obj: any = {}
            obj = keyValueToSet.map((currentIndexes:any) => {
                return obj[e['key-'+currentIndexes]] 
                    = e['value-'+currentIndexes]
            })
            data = {keys_to_add: obj }
        }

        let headers = getConfigPost()

        axios.post(ENDPOINTS.REDIS_CACHE_CLEANER, data, {headers})
        .then((res: any) => {
            notify('Success', "info", "", undefined, 50000);
          })
          .catch((error) => {
            
          notify(error?.response?.data?.message || 'Error while '+e.category, "error", "", undefined, 50000)
          });
    }

    const onSelectChange = (e: any) => {

        setDisableButton(false)
        setDelectKeys(false)
        setAddKeys(false)

        setSelectedInput(e.target.value);
        if(e.target.value === 'Delete Keys'){
            setDelectKeys(true) 
        }
        if(e.target.value === 'Add Keys'){
            setAddKeys(true) 
        }
    }

    const addRow = (e: any) => {
        setKeyValueToSet(keyValueToSet => [...keyValueToSet, counter])
        setCounter(counter+1)
    }

    const handleDelete = (index: number, e: any) => {
        keyValueToSet.splice(index, 1);
        let temp = keyValueToSet
        setKeyValueToSet(keyValueToSet => [...temp])
    }
    const CustomRow = ({keyValueToSet}: any) => {
        
        const createElements = (n: any)  => {
            let elements = [];
            for(let i =0; i < keyValueToSet.length; i++){
                elements.push(
                  <tbody key={i}>
                    <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Field className={classes.keyValueField}
                                name={'key-' + keyValueToSet[i]}
                                // @ts-ignore
                                component={renderInput}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Field className={classes.keyValueField}
                                autoFocus
                                name={'value-' + keyValueToSet[i]}
                                // @ts-ignore
                                component={renderInput}
                                />
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Button color="primary" onClick={(e: any) => handleDelete(i, e)}>X</Button>    
                            </TableCell>
                    </TableRow>
                  </tbody>
                );
            }
            return elements;
        }
    
        
        const listItems = 
              <FormGroup row>
                <table>
                    <thead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Key</TableCell>
                            <TableCell className={classes.tableCell}>Value</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                    </thead>
                  {createElements(keyValueToSet)}
                    </table>
              </FormGroup>
    
          return (
            listItems
          )
      }
    
    const { Form } = withTypes<any>();
    return (
        <>
        <Form onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <SelectInput onChange={onSelectChange} source="category" choices={[
                                    { id: 'Delete All Keys', name: 'Delete All Keys' },
                                    { id: 'Delete Keys', name: 'Delete Keys' },
                                    // { id: 'Add Keys', name: 'Add Keys' },
                                ]} />
                        </div>
                    </div>
                    {delectKeys ? <DeleteKeys /> : ''}
                    {addKeys ? <>
                                        <CustomRow keyValueToSet={keyValueToSet}/> 
                                        <FormGroup row>
                                            <Button color="primary" className="float-right" 
                                            onClick={addRow} >Add New Row</Button>
                                    </FormGroup>
                                </>
                                : '' }
                    <CardActions>
                            <Button
                            variant="contained"
                            type="submit"
                            color='primary'
                            disabled={disableButton}
                            fullWidth
                            
                            >
                                {selectedInput}
                            </Button>
                    </CardActions>
                </div>
            </form>
        )}
        />
      
        </>
    )
}


const DeleteKeys = () => {
    return (
        <>
         <Field
                  autoFocus
                  name="user_ids"
                  // @ts-ignore
                  component={renderInput}
                />
        </>
        
    )
}


export default RedisCacheCleaner
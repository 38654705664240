import * as React from "react";
import { useState } from "react";

import { withTypes } from "react-final-form";

import axios from "axios";

import { Button, CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { downloadCSV, Notification, useNotify } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigPost } from "../getConfig";
import { chunkArray } from "./PNNotifier";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  moveRight: {
    paddingLeft: "30%",
    margin: "1.5em 1.5em 0em 1.5em",
  },
  exportButton: {
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "70px",
  },
}));

interface FormValues {}

const { Form } = withTypes<FormValues>();

function RemortgageLetter() {
  const classes = useStyles();
  const notify = useNotify();

  const [csvOutput, setCsvOutput] = useState([]);
  const fileReader = new FileReader();
  const [documentURL, setDocumentURL] = useState([]);

  const handleOnChange = (e: any) => {
    if (e.target.files[0]) {
      fileReader.readAsText(e.target.files[0]);
      fileReader.onload = function (event: any) {
        const csvOutput = event.target.result;
        let temp = csvOutput.split("\n");
        temp = temp.filter((t: any) => {
          return t !== "";
        });
        setCsvOutput(temp);
      };
    }
  };

  const handleSubmit = () => {
    let currentCsv: string[] = csvOutput;

    if (!csvOutput.length) {
      notify("select a csv file", "error", "", undefined, 50000);
      return;
    }
    let headers = getConfigPost();

    let chunkArr = chunkArray(currentCsv, 300)  
    let result: any =[]
    let resultCounter = 0
    chunkArr.forEach((i)=> {
      let data = {
        listOfEmails: i,
      };
  
      axios
        .post(ENDPOINTS.REMORTGAGE_LETTER, data, { headers })
        .then((res: any) => {
          result = result.concat(res.data)
          resultCounter++
          if(resultCounter === chunkArr.length){
            setDocumentURL(result);
            notify(`success`, "info", "", undefined, 50000);
          }
        })
        .catch((error) => {
          notify(
            error?.response?.data?.message || "error",
            "error",
            "",
            undefined,
            50000
          );
        });
    })
  };


  const customExporter = (records: any) => {
    let csv = "First Name, Name, Address, Interest Savings, Time Savings, Home ownership% \n"
    csv = csv + documentURL
    .map((item: any, index: number) => {
      item = item.map((i: any, ind: number) => {
        console.log("ind ", ind)
        if(ind === 3){
          console.log("i", i)
          console.log("i.split('£') ", i.split('£'))
          console.log("i.split('£')[1] ", i.split('£')[1])
          console.log("Number(i.split('£')[1]) ", Number(i.split('£')[1]))
          console.log("Number(i.split('£')[1]).toLocaleString() ", Number(i.split('£')[1]).toLocaleString())
          console.log('"£' + Number(i.split('£')[1]).toLocaleString()+ '"')
        }
        return ind === 2 ? '"' +i.split(",").join(",\n") + '"' :  ind === 3 ? '"£' + Number(i.split('£')[1]).toLocaleString()+ '"' : i
      })
      console.log("item", item)
      return item.join(",");
    })
    .join("\n");
    console.log("csv ", csv)
    return downloadCSV(csv, "remortgage_letter_csv");
  };

  
  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <input
                    className={classes.moveRight}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                    onClick={handleOnChange}
                  />
                  <p>
                    Upload a <b>CSV</b> file. Keep all <b>Email</b> in the A1
                    column. Refresh the page before re-uploading the file.
                  </p>
                </div>
              </div>
              <Notification />
              <CardActions>
                <Button variant="contained" type="submit" color="primary">
                  Generate Remortgage Letter CSV
                </Button>
              </CardActions>
              {
                documentURL.length ?    <div className={classes.exportButton}>
                <Button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-46 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
                  onClick={() => {
                    customExporter(documentURL);
                  }}
                  // disabled={
                  //   !creditReportResult.length ||
                  //   !(userData.email.length > 0) ||
                  //   !addresses.length
                  // }
                >
                  <svg
                    className="MuiSvgIcon-root RaButton-smallIcon-49"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                  </svg>{" "}
                  Export
                </Button>
              </div> : ""
              }
              {/* {documentURL.length ? <a href={documentURL} target="_blank">Remortgage Letter</a> : ""} */}
            </div>
          </form>
        </>
      )}
    />
  );
}

export default RemortgageLetter;


export const getConfigGet = (params: any) => {
  const accessToken =  localStorage.getItem('accessToken') || ''
  return {
   headers: {
     Authorization: `Bearer ${accessToken}`,
     "Content-Type": "application/json"
    },
   params: params
 }
}

export const getConfigPost = () =>{
  const accessToken =  localStorage.getItem('accessToken') || ''
  return { 
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
  };
} 
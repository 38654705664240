import * as React from "react";
import { useEffect, useState } from "react";

import { withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import {
  Notification,
  useNotify,
  AutocompleteInput,
} from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import { getConfigGet, getConfigPost } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
    display: "flex"
  },
  actions: {
    padding: "0 1em 1em 1em",
  }
}));

interface FormValues {
  brandName?: string,
  blacklistBrandName?: string
}

const { Form } = withTypes<FormValues>();

const UpdateGiftCardBlacklist = () => {
  const classes = useStyles();
  const notify = useNotify();

  const [brandName, setBrandName] = useState([{ id: "", name: "" }]);
  const [blacklistBrandName, setBlacklistBrandName] = useState([{ id: "", name: "" }]);
  const [selectedBrandToAdd, setSelectedBrandToAdd] = useState("")
  const [selectedBrandToRemove, setSelectedBrandToRemove] = useState("")
  const [allGiftcardBrands, setAllGiftcardBrands] = useState("")
 

  const RemoveFromBlackList = () => {
    let config = getConfigGet({ brand_name: selectedBrandToRemove });
      axios.delete(ENDPOINTS.UPDATE_GIFT_CARD_BLACKLIST, config)
        .then((res: any) => {
          notify(`brand - ${selectedBrandToRemove} successfully removed to blacklist`, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed to remove from blacklist', "error", "", undefined, 50000)
        });

  }
  const AddToBlackList = () => {
    let headers = getConfigPost()
        
    let data = {
      brand_name: selectedBrandToAdd
    }
    axios.post(ENDPOINTS.UPDATE_GIFT_CARD_BLACKLIST, data, {headers})
    .then((res: any) => {
      notify(`brand - ${selectedBrandToAdd} successfully added to blacklist`, "info", "", undefined, 50000);
    })
    .catch((error) => {
      notify(error?.response?.data?.message || 'Failed to add to blacklist', "error", "", undefined, 50000)
    });

  }

  const DisableAllGiftcardBrands = (inputData: any) => {
      let headers = getConfigPost()
      let data = {    
        all_giftcard_brands: inputData
      }
      axios.patch(ENDPOINTS.UPDATE_GIFT_CARD_BLACKLIST, data, {headers})
        .then((res: any) => {
          notify(`success`, "info", "", undefined, 50000);
          setAllGiftcardBrands(inputData);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || 'Failed to remove from blacklist', "error", "", undefined, 50000)
        });

  }

  const handleSubmit = (inputData: any) => {};

  useEffect(() => {
    const config = getConfigGet({});
    const dataRes = async () =>
      axios
        .get(ENDPOINTS.UPDATE_GIFT_CARD_BLACKLIST, config)
        .then((res: any) => {
          setBrandName(
            res.data[0].brandNames.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setBlacklistBrandName(
            res.data[0].blacklistBrandNames.map((code: any) => {
              return { id: code, name: code };
            })
          );
          setAllGiftcardBrands(res.data[0].allGiftcardBrands);
        })
        .catch((error) => {
          notify(error?.response?.data?.message, "error", "", undefined, 50000);
        });

    dataRes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    // if (!values.Email) {
    //   errors.Email = translate("ra.validation.required");
    // }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <AutocompleteInput  
                    source="brandName"
                    choices={brandName}
                    onSelect={(event: any ) => {setSelectedBrandToAdd(event.name)}}
                  />                
                  <CardActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary" onClick={AddToBlackList}>
                      Add To Blacklist
                    </Button>
                  </CardActions>
                </div>
                <div className={classes.input}>
                  <AutocompleteInput
                    source="blacklistBrandName"
                    choices={blacklistBrandName}
                    onSelect={(event: any ) => {setSelectedBrandToRemove(event.name)}}
                  />  
                  <CardActions className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary"  onClick={RemoveFromBlackList}>
                      Remove From Blacklist
                    </Button>
                  </CardActions>
                </div>
                <div style={{ paddingTop: "6em" }} className={classes.input}>
                  <CardActions style={{ paddingLeft: "5em" }} className={classes.actions}>
                    <Button variant="contained" type="submit" color="primary"  onClick={() => DisableAllGiftcardBrands(allGiftcardBrands === 'enabled' ? 'disabled': 'enabled')}>
                      {allGiftcardBrands === 'enabled' ? 'Disable All Giftcard Brands':'Enable All Giftcard Brands'}
                    </Button>
                  </CardActions>
                </div>
                <p style={{width: '30em', wordWrap: 'break-word'}}>
                  Note: This action will enable or disable all gift cards, making them invisible to users. This will not impact previously purchased gift cards.
                </p>
              </div>
              <Notification />
              <CardActions className={classes.actions}>
              
                
              </CardActions>
            </div>
        </>
      )}
    />
  );
};

export default UpdateGiftCardBlacklist;

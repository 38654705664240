import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";
import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify, SelectInput } from "react-admin";

import { ENDPOINTS } from "../apiConstants";
import {getConfigGet, getConfigPost} from "../getConfig";
import { ShowData } from "./transferRewardAmount";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  search: {
    padding: '10px',
    margin: '16px'
  },
  button: {
    margin: '1em'
  },
  center: {
    textAlign: 'center'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  Email?: string;
}


const { Form } = withTypes<FormValues>();

const UpdateUserPendingAction = () => {

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [userPendingAction, setUserPendingAction] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [taskToAdd, setTaskToAdd] = useState([]);
  const [taskToRemove, setTaskToRemove] = useState([]);

  const [selectedActionToAdd, setSelectedActionToAdd] = useState("");
  const [selectedActionToRemove, setSelectedActionToRemove] = useState("");
  
  
  const onSelectActionToAdd = (e: any) => { setSelectedActionToAdd(e.target.value) }
  const onSelectActionToRemove = (e: any) => { setSelectedActionToRemove(e.target.value) }
  const clearActionToAdd = () => { setSelectedActionToAdd("") }
  const clearActionToRemove = () => { setSelectedActionToRemove("") }
  
  const handleSubmit = (inputData: any) => {

    setUserPendingAction([])
    setUserEmail(inputData.Email)
    notify("pos.fetching_the_result");

    let config = getConfigGet({email: inputData.Email})  

    axios.get(ENDPOINTS.UPDATE_USER_PENDING_ACTION, config)
          .then((res: any) => {
              setLoading(false);
              clearActionToAdd()
              clearActionToRemove()

              let pendingActions = res?.data[0]
              setUserPendingAction(pendingActions)
              const allPendingActions = pendingActions.map((action: any) => {return action[1]})
              const notEndedActions = pendingActions.filter((action: any) => { return action[3] === null })
                                                .map((action: any) => {return action[1]})
              
              const task = res?.data[1].map((task: any, index: any) => { 
                return ({ id: Object.keys(task)[0], name: Object.values(task)[0], key: Object.keys(task)[0] })
              })

              const taskToAdd = task.filter((action: any) => {
                return !notEndedActions.includes(Number(action.key))
              })
              const taskToRemove = task.filter((action: any) => {
                return allPendingActions.includes(Number(action.key)) && notEndedActions.includes(Number(action.key))
              })
              setTaskToAdd(taskToAdd)
              setTaskToRemove(taskToRemove)
              notify('Success', "info", "", undefined, 20000);
          })
          .catch((error) => {
              notify(error?.response?.data?.message || 'Failed get user pending action', "error", "", undefined, 50000)
          });
  };

  const validate = (values: FormValues) => {
      const errors: FormValues = {};
      if (!values.Email) {
        errors.Email = translate("ra.validation.required");
      }
      return errors;
    };


  return (
    <>
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="Email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                    Get User Pending Action
                  </Button>
              </CardActions>
              <Notification />
            </div>
          </form>
          <PendingTask  
              userEmail = {userEmail}
              userPendingAction = {userPendingAction}
              taskToAdd = {taskToAdd}
              taskToRemove = {taskToRemove}
              selectedActionToAdd = {selectedActionToAdd}
              onSelectActionToAdd = {onSelectActionToAdd}
              selectedActionToRemove = {selectedActionToRemove}
              onSelectActionToRemove = {onSelectActionToRemove}
              clearActionToAdd = {clearActionToAdd}
              clearActionToRemove = {clearActionToRemove}
          />
        
          </div>
        
      )}
    />
    </>
  );
};


const PendingTask = ({userEmail,
                       userPendingAction,
                       taskToAdd,
                       taskToRemove,
                       selectedActionToAdd,
                       onSelectActionToAdd,
                       selectedActionToRemove,
                       onSelectActionToRemove,
                       clearActionToAdd,
                       clearActionToRemove}: any) => {
    const classes = useStyles();
    const notify = useNotify();

    const onSelectActionToAddSub = (e: any) => {
      onSelectActionToAdd(e)
    }
    const onSelectActionToRemoveSub = (e: any) => {
      onSelectActionToRemove(e)
    }
    const  addPendingActionOnClick = (e: any) => {
        let headers = getConfigPost()
        let data = {
          email: userEmail,
          flag: selectedActionToAdd
        }

        axios.post(ENDPOINTS.UPDATE_USER_PENDING_ACTION, data, {headers})
        .then((res: any) => {
          clearActionToAdd()
          notify(`Success: `, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || `Failed `, "error", "", undefined, 50000)
        });
    }  

    const  RemovePendingActionOnClick = (e: any) => {
      let headers = getConfigPost()
      let data = {
        email: userEmail,
        flag: selectedActionToRemove
      }
      
      axios.patch(ENDPOINTS.UPDATE_USER_PENDING_ACTION, data, {headers})
        .then((res: any) => {
          clearActionToRemove()
          notify(`Success: `, "info", "", undefined, 50000);
        })
        .catch((error) => {
          notify(error?.response?.data?.message || `Failed `, "error", "", undefined, 50000)
        });
  }  
 
    return (
      <>  
        {userPendingAction.length>1 ?
            <div>
                <ShowData record={userPendingAction} tableCenterAlign={true}/>
            </div> : null}
        
       
            <div className={classes.center}>
              {taskToAdd.length ?  
                  <div>
                    <SelectInput onChange={onSelectActionToAddSub} 
                              autoFocus
                              source="Task To Add" 
                              choices= {taskToAdd} 
                              value = {selectedActionToAdd}/>
                    <Button
                      variant="contained"
                      type="submit"
                      color='primary'
                      className={classes.button}
                      onClick={addPendingActionOnClick}
                      disabled = {!selectedActionToAdd}
                      >Add User Pending Action
                    </Button>
                  </div>
                : null}
              
              {taskToRemove.length ?  
                  <div>
                    <SelectInput onChange={onSelectActionToRemoveSub} 
                              autoFocus
                              source="Task To Remove" 
                              choices= {taskToRemove}
                              value = {selectedActionToRemove} />
                    <Button
                      variant="contained"
                      type="submit"
                      color='primary'
                      className={classes.button}
                      onClick={RemovePendingActionOnClick}
                      disabled = {!selectedActionToRemove}
                      >Remove User Pending Action
                    </Button>
                  </div>
                : null}

        
            </div>
      </>
  )
}


export default UpdateUserPendingAction;
